<template>
<div class="section">
<div class="app">
  <div class="columns is-centered pt-5">
    <div class="column is-one-third">
      <div class="loginBox box  p-6">
        <div class="rows">
          <div class="row pb-5">
            <img src="@/assets/logo.png" ratio="16by8" />
          </div>
          <div class="row p-4">
            <b-field label="Nombre de Usuario" class="is-flex is-flex-direction-column is-align-items-center loginInput">
              <b-input size="is-small" :loading="cargando" style="width: 100%;" v-model="user" ref="fieldUser" @keypress.enter.native="iniciarSesion()"
                placeholder="Ingresa Nombre de Usuario" type="text" rounded required />
            </b-field>
            <b-field label="Contraseña" class="is-flex is-flex-direction-column is-align-items-center loginInput">
              <b-input size="is-small" :loading="cargando" style="width: 100%;" v-model="password" ref="fieldPassword" @keypress.enter.native="iniciarSesion()"
                placeholder="Ingresa Nombre Contraseña" type="password" password-reveal rounded required />
            </b-field>
          </div>
          <div class="row pt-2 is-flex  is-justify-content-center">
            <b-message type="is-danger" size="is-small" v-model="isActive" icon-size="is-small" style="width:100%"
              auto-close progress-bar :duration="3000" has-icon>
              <Strong>Error: </Strong>{{ mensaje }}
            </b-message>
          </div>
          <div class="row pt-4 is-flex  is-justify-content-center">
            <b-button class="loginButon" :loading="cargando" @click="iniciarSesion()"  type="is-info" inverted outlined>
              <strong>Iniciar sesión</strong>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>
<script>
import UsuariosService from '@/services/UsuariosService'
import EventBus from '@/EventBus'
export default {
  beforeCreate: function () {
    document.body.className = 'Login'
  },
  name: 'loginView',
  data: () => ({
    user: '',
    password: '',
    cargando: false,
    mensaje: null,
    isActive: false
  }),
  methods: {
    async iniciarSesion () {
      const fields = ['fieldUser', 'fieldPassword']
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      try {
        this.cargando = true
        const usuarioLogueado = await UsuariosService.login(
          this.user,
          this.password
        )
        if (usuarioLogueado[0] === false) {
          this.mensaje = usuarioLogueado[1]
          this.isActive = true
        } else if (usuarioLogueado.errorInfo) {
          this.mensaje = usuarioLogueado.errorInfo[2] + '. Comunicarse con el area de IT.'
          this.isActive = true
        } else if (usuarioLogueado) {
          EventBus.establecerUsuario(usuarioLogueado)
          EventBus.$emit('navegarHacia', 'Inicio')
        } else {
          this.mensaje = 'No se pudo iniciar sesion. Comunicarse con el area de IT.'
          this.isActive = true
        }
      } catch (e) {
        this.mensaje = e
        this.isActive = true
      } finally {
        this.cargando = false
      }
    },
    message (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    }
  }
}
</script>
<style >
.loginBox.box {
  background-color: #304ec375;
  border-radius: 20px;
  box-shadow: 10px 5px 5px rgba(101, 101, 101, 0.679);
}

.loginButon.is-info.is-inverted.is-outlined:hover,
.loginButon.is-info.is-inverted.is-outlined.is-hovered,
.loginButon.is-info.is-inverted.is-outlined:focus,
.loginButon.is-info.is-inverted.is-outlined.is-focused {
  background-color: #fff;
  color: #f08616 !important;
}
login.taginput .is-danger.taginput-container.is-focusable,
.is-danger.textarea,
.is-danger.input {
  border-color: #f1be46;
  -webkit-box-shadow: 0 0 0 0.125em rgba(251, 137, 16, 0.3);
  box-shadow: 2px 2px 2px 1px rgba(251, 137, 16, 0.3);
}

.loginInput .help.is-danger {
  color: #f1be46;
}

.loginInput .input:focus {
  border-color: #579ad5;
  -webkit-box-shadow: 0 0 0 0.125em rgba(87, 127, 213, 0.5);
  box-shadow: 0 0 0 0.125em rgba(87, 129, 213, 0.5);
}

.loginInput label {
  color: #fff;
}

.loginInput .has-text-primary {
  color: #ff9650c9 !important;
}
</style>
