<template>
  <div class="section m-1">
    <b-modal v-model="modalActive" has-modal-card>
      <form action="">
        <div class="modal-card has-text-centered" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">¡IMPORTANTE!</p>
          </header>
          <section class="modal-card-body">
            <b-message type="is-warning" size="is-small" has-icon>
              Verifica que los datos sean correctos antes de continuar.
            </b-message>
            <label for="">Selecciona destino de Reserva:</label>
            <div class="column">
              <b-field style="display: flex; flex-direction: column">
                <b-select v-model="destino" placeholder="Selecciona destino" ref="fieldDestino" rounded required>
                  <option value="Nacional">Nacional</option>
                  <option value="Internacional">Internacional</option>
                </b-select>
              </b-field>
            </div>
          </section>
          <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button label="Continuar" type="is-primary" @click="confirmCustom()">
            </b-button>
          </footer>
        </div>
      </form>
    </b-modal>
    <div class="is-flex is-justify-content-center" width="100%">
      <h1 class="title">RESERVACIONES HOTELES CONTRAVEL</h1>
    </div>
    <div class="columns pt-6">
        <div class="column is-flex is-align-items-center" v-if="datos">
          <b-field>
            <img class="image" :src="require('@/assets/' + datos[0].descrip + '.png')" :alt="datos[0].descrip">
          </b-field>
        </div>
        <div class="column is-flex is-align-items-center is-justify-content-center" v-if="datos">
          <div class="content">
            <h5 class="subtitle m-0">
              {{dk_agencia}}
            </h5>
          </div>
        </div>
        <div class="column has-text-centered is-flex is-align-items-center">
          <p>{{message}}</p>
          <b-field :type="status" style="width: 100%;">
            <b-input style="width:100%" size="is-small" :class="status" placeholder="Busqueda por Localizador" v-model="reserva" @keypress.enter="obtenReserva()"
            rounded />
            <p class="control">
              <b-button size="is-small" rounded :type="status" icon-left="magnify" @click="obtenReserva()" />
            </p>
        </b-field>
        </div>
    </div>
    <div class="columns" v-if="datos">
    <div class="column">
      <div class="columns">
        <div class="column is-flex is-justify-content-center">
          <div class="content">
            <label class="pr-2">Precio Neto Proveedor:</label>
            <span class="tag is-medium">
              $ &nbsp; {{ datos[0].PRECIO_NETO }}
            </span>
          </div>
        </div>
        <div class="column is-flex is-justify-content-center">
          <label class="pr-2">Concepto:</label>
          <span class="tag is-medium">
             {{ concepto }}
          </span>
        </div>
      </div>
      <div class="columns">
        <div class="column is-flex  is-flex-direction-column has-text-centered">
          <label class="pr-2 mb-2">Monto Venta:</label>
          <span class="tag is-medium">
            $ &nbsp; {{ datos[0].PRECIO_TOTAL_FINAL }}
          </span>
        </div>
        <div class="column is-flex  is-flex-direction-column has-text-centered">
          <label class="pr-2 mb-2">Comision:</label>
          <span class="tag is-medium">
            $ &nbsp; {{ comision_agencia }}
          </span>
        </div>
        <div class="column is-flex  is-flex-direction-column has-text-centered">
          <label class="mb-2">Serie:</label>
          <b-field class="is-block">
            <b-select v-model="serie" size="is-small" rounded required ref="fieldSerie">
              <option disabled value="">Selecciona la serie de la Factura</option>
              <option value="PPD">FAI3 - Agencia</option>
              <option value="PUE">FEI3 - Empresarial</option>
            </b-select>
          </b-field>
        </div>
        <div class="column  is-flex  is-flex-direction-column has-text-centered">
          <label class="mb-2">Cargo por Servicio:</label>
          <b-field class="is-flex is-flex-direction-column is-align-items-center">
            <span class="tag is-medium" v-if="!status_cxs">
              {{ this.valor_cxs }}
            </span>
            <b-input v-if="status_cxs" v-model="valor_cxs" size="is-small" required ref="fieldCxS" @keyup.native="calcularTotal()" />
            <b-button type="is-info is-light" size="is-small" @click="cambiarValor()" :icon-left="icon" />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-flex  is-flex-direction-column has-text-centered is-one-third">
          <label class="mb-2">Observaciones:</label>
          <b-field class="is-block">
            <b-input rounded size="is-small" placeholder="Observaciones" v-model="obs" />
          </b-field>
        </div>
        <div class="column is-flex  is-flex-direction-column has-text-centered">
          <label class="mb-2">Metodo de Pago:</label>
          <b-field class="is-block">
            <b-select v-model="mp" size="is-small" rounded required ref="fieldMP">
              <option disabled value="">Selecciona un Metodo de Pago</option>
              <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
              <option value="PUE">PAGO EN UNA SOLA EXHIBICION</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-flex  is-flex-direction-column has-text-centered">
          <label class="mb-2">Precio Total Pagado:</label>
          <span class="tag is-medium">
            $ &nbsp; {{ this.result }}
          </span>
        </div>
        <div class="column is-flex  is-flex-direction-column has-text-centered">
          <label class="mb-2">Precio Neto Agencia:</label>
          <span class="tag is-medium">
            $ &nbsp; {{ this.datos[0].PRECIO_AGENCIA_FINAL }}
          </span>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-two-fifths is-flex  is-flex-direction-row has-text-centered is-justify-content-center">
            <label>Forma de Pago:</label>
            <span class="tag is-medium">{{ this.datos[0].TIPO_PAGO }}</span>
            <b-field class="file p-0">
            <b-upload ref="avatarInput" accept="image/gif,image/jpeg,image/jpg,image/png" @input="changeImage($event)">
            <b-tooltip label="Subir Comprobante de Pago" position="is-bottom" type="is-info is-light">
              <a class="button is-light is-small" style="font-size: .8rem">
                <b-icon icon="upload" type="is-info" size="is-small" style="font-size: 1.5rem"></b-icon>
              </a>
            </b-tooltip>
          </b-upload>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-three-quarters">
          <div class="rows" v-if="avatar">
            <div class="row is-flex is-justify-content-end">
              <a @click="eliminarview()">
                <b-icon icon="close"> </b-icon>
              </a>
            </div>
          <div class="row is-flex is-justify-content-center">
            <div class="content is-flex is-justify-content-center">
              <img :src="avatar" class="image" style="max-width: 85%; max-height: 85%;" />
            </div>
          </div>
      </div>
        </div>
      </div>
    </div>
    </div>
    <b-button v-if="datos" v-show="!modalActive" @click="validar()" class="save" size="is-medium" icon-left="content-save"
      :loading="loading" rounded
    />
  </div>
</template>
<script>
import HotelServices from '@/services/HotelServices'
export default {
  name: 'HotelesComponent',
  props: ['value'],
  data () {
    return {
      serie: '',
      mp: '',
      destino: null,
      radioButton: null,
      modalActive: false,
      obs: null,
      datos: null,
      loading: false,
      icon: 'pencil',
      status: 'is-info is-light',
      avatar: '',
      file: null,
      reserva: null,
      message: '',
      concepto: null,
      dk_agencia: null,
      comision_agencia: null,
      cxs: null,
      status_cxs: false,
      valor_cxs: null,
      result: null,
      porcentaje: null
    }
  },
  mounted () {
  },
  methods: {
    validar () {
      const fields = ['fieldSerie', 'fieldMP']
      if (this.$refs.fieldCxS) {
        fields.push('fieldCxS')
      }
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }

      this.modalActive = true
    },
    mensaje (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    },

    confirmCustom () {
      this.$buefy.dialog.confirm({
        title: 'Atención',
        message: `¿La Reservacion capturada forma parte de un paquete?
        <b>Nota: </b>de ser asi, no se facturara hasta que el paquete este completo, en caso de que la reservación no sea un paquete pasara al proceso de facturación directamente`,
        cancelText: 'No',
        confirmText: 'Si',
        type: 'is-success',
        onConfirm: () => this.generarFact('true'),
        onCancel: () => this.generarFact()
      })
    },

    async generarFact (paquete) {
      if (paquete === 'true') {
        paquete = 1
      } else {
        paquete = 0
      }
      const respuesta = await this.save(paquete)
      if (respuesta) {
        const factura = await HotelServices.generarFactura(this.reserva)
        if (factura) {
          this.mensaje('Archivo File generado correctamente', 'is-success')
          this.reserva = null
        } else {
          this.mensaje(
            'Error al generar archivo File por favor contactar al encargado de Facturacion',
            'is-warnig'
          )
          this.reserva = null
        }
      }
    },
    async save (paquete) {
      console.log(this.datos[0])
      if (!this.file && this.fpago != null) {
        this.mensaje('Debes de subir el comprobante de pago.', 'is-warning')
        return
      }
      const fields = ['fieldDestino']

      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }

      const result = await HotelServices.saveData(
        this.datos[0],
        this.comision_agencia,
        this.result,
        this.valor_cxs,
        this.obs,
        this.destino,
        this.mp,
        this.serie,
        paquete
      )
      if (result === true) {
        if (this.$refs.avatarInput) {
          const uploadImage = await HotelServices.uploadImage(this.file)
          if (uploadImage === true) {
            this.mensaje(
              'La reservacion a sido registrada con exito',
              'is-success'
            )
            this.modalActive = false
            this.interruptor = !this.interruptor
          } else {
            this.mensaje('Error al revisar la reservacion', 'is-danger')
          }
        } else {
          this.mensaje(
            'La reservacion a sido registrada con exito',
            'is-success'
          )
          this.modalActive = false
          this.interruptor = !this.interruptor
        }
      }
      if (result.errorInfo) {
        if (result.errorInfo[1] === 1062) {
          this.mensaje(
            'ERROR: Las Reservacion ya fue registrada.',
            'is-danger'
          )
        } else {
          this.mensaje(
            'ERROR: ' + result.errorInfo[1] + ': ' + result.errorInfo[2],
            'is-danger'
          )
        }
      }
    },
    eliminarview () {
      this.avatar = null
      this.file = ''
    },
    changeImage (e) {
      this.file = e
      const reader = new FileReader()
      const that = this
      reader.readAsDataURL(this.file)
      reader.onload = function (e) {
        that.avatar = this.result
      }
    },
    calcularTotal () {
      if (this.valor_cxs === '') {
        this.result = this.datos[0].PRECIO_COBRO
      } else {
        this.result = this.datos[0].PRECIO_COBRO + Number(this.valor_cxs)
      }
    },
    cambiarValor () {
      this.status_cxs = !this.status_cxs
      if (this.status_cxs === true) {
        this.icon = 'close'
        this.valor_cxs = null
      } else {
        this.valor_cxs = 'CONTRA REPORTE'
        this.result = this.datos[0].PRECIO_COBRO
        this.icon = 'pencil'
      }
    },
    async obtenReserva () {
      if (this.reserva === null || this.reserva === '') {
        this.message = 'Llena el campo necesario para la busqueda'
        this.datos = null
        this.status = 'is-warning'
        this.valor_cxs = null
        this.dk_agencia = null
        this.comision_agencia = null
      } else {
        this.datos = await HotelServices.obtenerReserva(this.reserva)
        if (this.datos.length > 0) {
          this.dk_agencia =
            this.datos[0].CVE_AGENCIA + ' - ' + this.datos[0].NOM_AGENCIA
          this.concepto =
            this.datos[0].NOMBRE_HOTEL +
            ' - ' +
            this.datos[0].NOMBRE +
            ' ' +
            this.datos[0].APELLIDOS
          this.comision_agencia = (
            this.datos[0].PRECIO_TOTAL_FINAL -
            this.datos[0].PRECIO_AGENCIA_FINAL
          ).toFixed(2)
          this.status = 'is-success'
          this.valor_cxs = 'CONTRA REPORTE'
          this.result = this.datos[0].PRECIO_COBRO
          this.message = null
        } else {
          this.message = 'No se encontraron resultados'
          this.status = 'is-danger'
          this.result = null
          this.datos = null
          this.dk_agencia = null
          this.comision_agencia = null
        }
      }
    }
  },
  computed: {
    interruptor: {
      get () {
        console.log(this.value)
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
button.save {
  color: #ffffff; /* Color del texto */
  border-radius: 20px; /* Borde del boton */
  letter-spacing: 1px; /* Espacio entre letras */
  background-color: #1eb3e9; /* Color de fondo */
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
button.save:hover {
  background-color: #1261a7; /* Color de fondo al pasar el cursor */
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  color: #ffffff; /* Color del texto */
  transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
  button.save {
    color: #ffffff; /* Color del texto */
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    right: 20px;
  }
}

</style>
