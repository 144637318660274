<template>
  <div style="width: 100%; background-color: white !important;">
    <b-tabs type="is-boxed is-centered">
      <b-tab-item label="Hoteles Contravel" icon="book">
        <Hoteles v-model="interruptor" />
      </b-tab-item>
      <b-tab-item class="is-info" label="Hoteles Externos" icon="bank">
        <Externos v-model="interruptor" />
      </b-tab-item>

      <b-tab-item label="Autos" icon="car">
      <Autos />
      </b-tab-item>
      <b-tab-item label="Trenes" icon="train">
        <p>Trenes</p>
        <!-- <Trenes /> -->
      </b-tab-item>
      <b-tab-item label="Circuitos" icon="map">
        <p>Circuitos</p>
        <!-- <Circuitos /> -->
      </b-tab-item>
      <b-tab-item label="Seguros/Tours/Otros" icon="kit-medical">
        <p>Seguros</p>
        <!-- <Seguros /> -->
      </b-tab-item>
      <b-tab-item label="Paquetes" icon="box">
        <p>Paquetes</p>
        <!-- <Paquetes /> -->
      </b-tab-item>
    </b-tabs>
    <div class="body">
      <router-view />
    </div>
  </div>
</template>

<script>
import Hoteles from '@/components/servicios/Desgloce/Hoteles'
import Externos from '@/components/servicios/Desgloce/HotelExt'
import Autos from '@/components/servicios/Desgloce/Autos'
/* import Paquetes from "@/components/Paquetes";
import Trenes from "@/components/Trenes";
import Seguros from "@/components/Seguros";
import Circuitos from "./Circuitos.vue"; */
export default {
  name: 'DesglocesView',
  props: ['value'],
  components: {
    Hoteles,
    Externos,
    Autos
    /* Paquetes,
    NewReservation,
    Trenes,
    Seguros,
    Circuitos, */
  },
  data () {
    return {}
  },

  mounted () {},
  methods: {
    navegarHacia (nombreRuta) {
      this.$router.push({ name: nombreRuta })
      this.menuEstaActivo = false
    },
    message (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    }
  },
  computed: {
    interruptor: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
.tabs li.is-active a {
  border-bottom-color: hsl(38, 100%, 50%);
  color: hsl(38, 100%, 50%);
}
</style>
