<template>
  <section>
    <div class="is-flex is-justify-content-center">
      <strong>{{ nom_cliente }}</strong>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <b-field label="Agencia:">
          <b-autocomplete size="is-small" v-model="agencia" placeholder="Buscar Agencia" ref="fieldAgencia"
            :data="filtrarDataAgencias" field="NUM_CLIENTE" @select="option => obtenerAgencia(option)" rounded required>
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.NUM_CLIENTE + " - " + props.option.NOMBRE }}
                  <br />
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Notas">
          <b-input size="is-small" rows="1" type="textarea" v-model="notas"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-table :paginated="pagination" :per-page="pages" :data="isEmpty ? [] : listCotizaciones" mobile-cards
          narrowed>
          <b-table-column field="Servicio" label="Servicio" centered v-slot="props">
            <h3 style="font-size:80%"> {{ props.row.servicio }}</h3>
          </b-table-column>
          <b-table-column field="proveedor" label="Proveedor" centered v-slot="props">
            <h3 style="font-size:80%"> {{ props.row.proveedor }}</h3>
          </b-table-column>
          <b-table-column field="destino" label="Destino" centered v-slot="props">
            <h3 style="font-size:80%">{{ props.row.destino }}</h3>
          </b-table-column>
          <b-table-column field="observaciones" label="Observaciones" centered v-slot="props">
            <h3 style="font-size:80%">{{ props.row.observaciones }}</h3>
          </b-table-column>
          <b-table-column label="Accion" centered v-slot="props">
            <b-button size="is-small" @click="eliminar(props.row.id)" type="is-primary">Eliminar</b-button>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered">No hay datos</div>
          </template>
        </b-table>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field label="Servicio" style="width: 100%;">
          <b-select class="is-centered" size="is-small" v-model="servicioP"
          ref="fieldServices"
          @input="obtenerProveedores(servicioP)"  required rounded>
                            <option disabled value="">Selecciona un Servicio</option>
                            <option v-for="option in servicios" :value="option.id" :key="option.id">
                                {{ option.tipo }}
                            </option>
                        </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field v-if="servicio != 9" label="Proveedor" style="width: 100%;">
          <b-autocomplete ref="fieldProv" size="is-small" v-model="proveedor" placeholder="Buscar Proveedor"
            :data="filtrarDataProveedor" field="nombre" @select="option => (selected = option)" rounded
            :required="check">
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.nombre }}
                  <br />
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field label="Proveedor" v-if="servicio == 9">
          <b-input ref="fieldProv" size="is-small" placeholder="Proveedor" v-model="proveedor" rounded required>
          </b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Destino">
          <b-input ref="fieldDestino" size="is-small" placeholder="Destino" v-model="destino" rounded required>
          </b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Observaciones">
          <b-input ref="fieldObs" size="is-small" placeholder="Observaciones" v-model="observaciones" rounded required>
          </b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-flex is-justify-content-flex-start">
        <b-button size="is-small" label="agregar" type="is-success" icon-right="plus" @click="Arraypush()" />
      </div>
      <div class="column is-flex is-justify-content-end">
        <b-field>
          <b-field class="file is-primary is-flex is-flex-direction-column" :class="{ 'has-name': !!file }">
            <b-upload ref="fieldFile" v-model="file" class="file-label" rounded>
              <span class="file-cta" >
                <span class="file-label">{{ file.name || "Click para subir archivo" }}</span>
              </span>
            </b-upload>
          </b-field>
        </b-field>
      </div>
    </div>
  </section>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'
export default {
  name: 'appHoteles',
  props: ['value', 'ejecutivo'],
  data () {
    return {
      file: {},
      today: new Date(),
      check: false,
      pagination: false,
      pages: 3,
      servicioP: '',
      show: true,
      isEmpty: false,
      data: [],
      agencia: '',
      nom_cliente: '',
      proveedores: [],
      proveedor: '',
      cotizaciones: null,
      destino: null,
      observaciones: null,
      listCotizaciones: [],
      id: null,
      notas: null,
      servicios: {}
    }
  },
  mounted () {
    this.obtenerAgencias()
    this.obtenerServicios()
  },
  methods: {
    async obtenerServicios () {
      this.servicios = await SeguimientoService.obtenerServicios()
    },
    async guardarCotizacion () {
      const respuesta = await SeguimientoService.saveDataSeguimientos(
        this.agencia,
        this.nom_cliente,
        this.servicio,
        JSON.stringify(this.listCotizaciones),
        this.notas,
        this.file,
        this.ejecutivo
      )
      if (respuesta === true) {
        this.servicio = ''
        return respuesta
      } else {
        if (respuesta.errorInfo) {
          return respuesta.errorInfo[2]
        } else {
          return false
        }
      }
    },
    eliminar (index) {
      if (this.listCotizaciones.length <= 3) {
        this.pagination = false
      }
      this.listCotizaciones = this.listCotizaciones.filter((item) => item.id !== index)
    },
    Arraypush () {
      this.check = true
      const fields = ['fieldServices', 'fieldProv', 'fieldDestino', 'fieldObs']
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      if (this.listCotizaciones.length > 2) {
        this.pagination = true
      }
      this.listCotizaciones.push({
        id: this.id++,
        servicio: this.servicios[this.servicioP - 1].tipo,
        proveedor: this.proveedor,
        destino: this.destino,
        observaciones: this.observaciones
      })
      this.check = false
      this.proveedor = ''
      this.destino = null
      this.observaciones = null
    },
    async obtenerProveedores (servicio) {
      this.proveedor = ''
      this.proveedores = await SeguimientoService.obtenerProveedores(servicio)
    },
    obtenerAgencia (option) {
      if (option == null) {
        this.agencia = ''
      } else {
        this.agencia = option.NUM_CLIENTE
        this.nom_cliente = option.NOMBRE
      }
    },
    async obtenerAgencias () {
      this.data = await SeguimientoService.obtenerAgencias()
    }
  },
  computed: {
    filtrarDataAgencias () {
      return this.data.filter(option => {
        return (option.NUM_CLIENTE.toString().toLowerCase().indexOf(this.agencia.toLowerCase())) >= 0
      })
    },
    filtrarDataProveedor () {
      return this.proveedores.filter(option => {
        return (option.nombre.toString().toLowerCase().indexOf(this.proveedor.toLowerCase())) >= 0
      })
    },
    servicio: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
.pagination .pagination-next,
.pagination .pagination-previous,
.pagination-list {
  font-size: 10px;
}

.label,
.is-relative {
  font-size: 10pt;
}

.pagination {
  padding: 0px;
}

.table-wrapper:not(:last-child) {
  margin-bottom: 0rem;
}
</style>
