<template>
  <div style="width: 100%" v-if="!view">
    <div>
      <b-message v-if="warning" type="is-danger" size="is-small" has-icon>
        <strong>Error al obtener Estatus: </strong> ({{ warning }}).
        <strong>Comunicarse con el área de Soporte</strong>
      </b-message>
    </div>
    <div class="pb-3 is-flex is-justify-content-center" v-if="!warning">
      <b-field>
        <b-tooltip label="Cotizado" position="is-top" type="is-dark is-light" size="is-large" :animated="true">
          <b-radio-button v-model="estatus" size="is-small" :native-value="1" type="is-primary is-light is-outlined"
            :disabled="estatusHistorico > 0">
            <b-icon style="padding-left: 10px;" icon="file-sign" />
          </b-radio-button>
        </b-tooltip>
        <b-tooltip label="Confirmado" position="is-top" type="is-success is-light" size="is-large" :animated="true">
          <b-radio-button v-model="estatus" size="is-small" :native-value="2" type="is-success is-light is-outlined"
            :disabled="estatusHistorico > 1">
            <b-icon style="padding-left: 10px;" icon="check-outline" />
          </b-radio-button>
        </b-tooltip>
        <b-tooltip label="Pagado" position="is-bottom" type="is-info is-light" size="is-large" :animated="true">
          <b-radio-button v-model="estatus" size="is-small" :native-value="3" type="is-info is-light is-outlined"
            :disabled="estatusHistorico > 2 || estatusHistorico == 0">
            <b-icon style="padding-left: 10px" icon="cash-check" />
          </b-radio-button>
        </b-tooltip>
        <b-tooltip label="Cancelado" position="is-top" type="is-danger is-light" size="is-large" :animated="true">
          <b-radio-button v-model="estatus" size="is-small" :native-value="4" type="is-danger is-light is-outlined"
            :disabled="estatusHistorico > 3">
            <b-icon style="padding-left: 10px;" icon="close-box" />
          </b-radio-button>
        </b-tooltip>
        <b-tooltip label="Reembolso" type="is-warning is-light" position="is-bottom" :animated="true">
          <b-radio-button v-model="estatus" size="is-small" :native-value="5" type="is-warning is-light is-outlined"
            :disabled="estatusHistorico < 3">
            <b-icon style="padding-left: 10px;" icon="cash-refund" />
          </b-radio-button>
        </b-tooltip>
      </b-field>
    </div>
    <div class="is-flex is-justify-content-center">
      <a href="https://seguimientos.contravel.com.mx/Desglose/#/" target="_blank">
        <b-button v-if="estatusHistorico == 3" type="is-info" size="is-small" outlined>Realizar Desgloce</b-button>
        </a>
    </div>
    <b-message :type="typeMessage" size="is-small" auto-close :duration="2000" v-if="messageObs">
      {{ messageObs }}
    </b-message>
    <div class="rows">
      <div v-if="!warning && estatus == estatusHistorico" class="row">
        <b-collapse animation="fade" :open="isOpen" v-if="estatus <= 2">
          <template #trigger="props">
            <div class="card-header" role="button" aria-controls="contentIdForA11y3" :aria-expanded="props.open">
              <p class="card-header-title p-0 pl-2 pt-2" style="font-size:.8rem">
                Observaciones
              </p>
              <a class="card-header-icon p-0 pr-3">
                <b-icon size="is-small" :icon="props.open ? 'menu-up' : 'menu-down'">
                </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content p-0 pt-2">
            <div class="content">
              <b-field style="width: 100%;">
                <b-input v-model="observacion" ref="fieldObs" size="is-small" maxlength="200" type="textarea" rows="2"
                  :has-counter="false" required></b-input>
              </b-field>
              <b-field class="file is-primary is-flex is-justify-content-center" :class="{ 'has-name': !!file }">
                <b-upload v-model="file" class="file-label"
                  accept=".docx , .doc, .pdf, .jpg, .png, .jpeg, .gif, .zip, .rar" native>
                  <a class="button is-primary file-cta is-small">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                  </a>
                </b-upload>
                <span class="file-name is-small" v-if="file">{{ file.name }}</span>
                <b-button size="is-small" type="is-danger" v-if="file" @click="eliminarFile()" icon-right="close" />
              </b-field>
            </div>
            <b-field class="is-flex is-justify-content-end p-0">
              <b-button type="is-info" style="width: 100%" size="is-small" icon-right="content-save-outline"
                @click="actualizarNotas(id)">
                Actualizar</b-button>
            </b-field>
          </div>
        </b-collapse>
      </div>
      <div class="row" v-if="!warning && estatus != estatusHistorico">
        <section v-if="estatus == 2">
          <b-field label="Fecha Limite:">
            <b-datepicker :min-date="today" size="is-small" v-model="date" ref="fieldDate" expanded
              placeholder="Select a date">
            </b-datepicker>
            <b-button size="is-small" @click="$refs.fieldDate.toggle()" icon-left="calendar-today" type="is-primary" />
          </b-field>
        </section>
        <div class="card-content is-flex is-flex-direction-column is-align-items-center pb-0 pt-2">
          <b-field style="width: 100%;">
            <b-input v-model="observacion" ref="fieldObs" size="is-small" maxlength="200" type="textarea" rows="2"
              :has-counter="false" required></b-input>
          </b-field>
          <b-field class="file is-primary" :class="{ 'has-name': !!file }">
            <b-upload ref="fieldFile" v-model="file" class="file-label"
              accept=".docx , .doc, .pdf, .jpg, .png, .jpeg, .gif, .zip, .rar" native>
              <a class="button is-primary file-cta is-small">
                <b-icon class="file-icon" icon="upload"></b-icon>
              </a>
            </b-upload>
            <span class="file-name is-small" v-if="file">{{ file.name }}</span>
            <b-button size="is-small" type="is-danger" v-if="file" @click="eliminarFile()" icon-right="close" />
          </b-field>
        </div>
        <b-field class="is-flex is-justify-content-end p-2">
          <b-button @click="actualizarEstatus(id)" type="is-info" style="font-size: .8rem; width: 30rem;"
            icon-right="content-save-outline">
            Actualizar</b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'

export default {
  name: 'EstatusView',
  props: ['value', 'id', 'view'],
  data () {
    return {
      file: null,
      isOpen: false,
      estatus: null,
      estatusHistorico: null,
      warning: null,
      observacion: null,
      messageObs: null,
      typeMessage: null,
      today: new Date(),
      date: null
    }
  },
  mounted () {
    this.obtenerEstatus(this.id)
  },
  methods: {
    message (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    },
    eliminarFile () {
      this.file = null
    },
    async actualizarEstatus (id) {
      const fields = ['fieldObs', 'fieldFile']
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      if (this.estatus === 2) {
        if (this.date === null) {
          this.message('Añade una fecha limite para poder confirmar', 'is-danger')
          return
        }
      }
      const respuesta = await SeguimientoService.updateStatus(id, this.file, this.observacion, this.estatus)
      if (respuesta === true) {
        if (this.estatus === 2) {
          this.actualizarFechaLimit(this.date, id)
        }
        this.subInterruptor = !this.subInterruptor
        this.observacion = null
        this.file = null
        this.obtenerEstatus(this.id)
        this.message('Se actualizo el estatus de la cotizacion: ' + id + ' Correctamente!', 'is-success')
        this.$emit('recargar')
      }
      this.subInterruptor = !this.subInterruptor
    },
    async actualizarFechaLimit (date, id) {
      // eslint-disable-next-line
      const actualiza = await SeguimientoService.actualizarFechaLimit(date, id)
    },
    async actualizarNotas (id) {
      const fields = ['fieldObs']
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      const respuesta = await SeguimientoService.saveObs(this.file, id, this.observacion)
      if (!respuesta.errorInfo) {
        this.subInterruptor = !this.subInterruptor
        this.observacion = null
        this.file = null
        this.messageObs = 'Datos agregados correctamente'
        this.typeMessage = 'is-success'
      } else if (respuesta.errorInfo) {
        this.typeMessage = 'is-danger'
        this.messageObs = 'Error: ' + respuesta.errorInfo[2]
      } else {
        this.typeMessage = 'is-danger'
        this.messageObs = 'Error: Comunicarse con el área de IT '
      }
    },
    async obtenerEstatus (id) {
      const respuesta = await SeguimientoService.obtenerEstatus(id)
      if (respuesta.errorInfo) {
        this.warning = respuesta.errorInfo[2]
      } else if (respuesta) {
        this.estatus = respuesta[0].estatus
        this.estatusHistorico = respuesta[0].estatus
      }
    }
  },
  computed: {
    subInterruptor: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
.mdi-24px.mdi::before {
  font-size: 18px;
}

span.file-name.is-small {
  font-size: 0.75rem;
}
</style>
