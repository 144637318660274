import HttpService from '@/services/HttpService'

const SeguimientoService = {
  async sendMail (cotizacion, address, urlBackend) {
    return await HttpService.post('/sendCotizacion.php', {
      cotizacion: cotizacion,
      address: address,
      urlBackend: urlBackend
    })
  },
  async obtenerEjecutivos () {
    return await HttpService.get('/obtenerEjecutivos.php')
  },
  async obtenerCorreos () {
    return await HttpService.get('/obtenerCorreos.php')
  },
  async obtenerData (data, fechas) {
    return await HttpService.post('/obtenerData.php', {
      data: data,
      fechas: fechas
    })
  },
  async obtenerAgentes () {
    return await HttpService.get('/obtenerAgentes.php')
  },
  async obtenerPermisos () {
    return await HttpService.get('/obtenerPermisos.php')
  },
  async obtenerCount (estatus) {
    return await HttpService.post('/obtenerCount.php', { estatus: estatus })
  },
  async obtenerLabels () {
    return await HttpService.get('/obtenerLabels.php')
  },
  async saveDataSeguimientos (cveAgencia, nomAgencia, servicio, cotizaciones, notas, file, ejecutivo) {
    const formData = new FormData()
    formData.append('cve_agencia', cveAgencia)
    formData.append('nom_agencia', nomAgencia)
    formData.append('servicio', servicio)
    formData.append('cotizaciones', cotizaciones)
    formData.append('notas', notas)
    formData.append('archivo', file)
    formData.append('ejecutivo', ejecutivo)
    console.log(formData)
    return await HttpService.formdata('/saveDataSeguimientos.php', formData)
  },
  async saveObs (file, cotizacion, nota) {
    const formData = new FormData()
    formData.append('archivo', file)
    formData.append('cotizacion', cotizacion)
    formData.append('nota', nota)
    return await HttpService.formdata('/saveObs.php', formData)
  },
  async updateStatus (cotizacion, file, nota, estatus) {
    const formData = new FormData()
    formData.append('archivo', file)
    formData.append('cotizacion', cotizacion)
    formData.append('nota', nota)
    formData.append('estatus', estatus)
    return await HttpService.formdata('/updateStatus.php', formData)
  },
  async obtenerEstatus (id) {
    return await HttpService.post('/obtenerEstatus.php', {
      id: id
    })
  },
  async obtenerDetalles (id) {
    return await HttpService.post('/obtenerDetalles.php', {
      id: id
    })
  },
  async obtenerCotizaciones () {
    return await HttpService.get('/obtenerCotizaciones.php')
  },
  async obtenerAgencias () {
    return await HttpService.get('/obtenerAgencias.php')
  },
  async obtenerServicios () {
    return await HttpService.get('/obtenerServicios.php')
  },
  async obtenerNotas (id) {
    return await HttpService.post('/obtenerNotas.php', {
      id: id
    })
  },
  async obtenerProveedores (servicio) {
    return await HttpService.post('/obtenerProveedores.php', {
      servicio: servicio
    })
  },
  async eliminarProveedor (id) {
    return await HttpService.post('/eliminarProveedor.php', {
      id: id
    })
  },
  async saveProveedor (id, proveedor, destino, observaciones) {
    return await HttpService.post('/saveProveedor.php', {
      id: id,
      proveedor: proveedor,
      destino: destino,
      observaciones: observaciones
    })
  },
  async saveProveedorP (id, servicio, proveedor, destino, observaciones) {
    return await HttpService.post('/saveProveedorP.php', {
      id: id,
      servicio: servicio,
      proveedor: proveedor,
      destino: destino,
      observaciones: observaciones
    })
  },
  async actualizarFechaLimit (date, id) {
    return await HttpService.post('/actualizarLimit.php', {
      date: date,
      id: id
    })
  }
}
export default SeguimientoService
