<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-button size="is-small" type="is-success" @click="modalAgregar = !modalAgregar" outlined>Agregar
        </b-button>
      </div>
      <!-- <div class="column pl-0">
        <b-button size="is-small" type="is-dark" @click="modalDesgloce = !modalDesgloce" outlined>Desgloce
        </b-button>
      </div> -->
    </div>
    <b-modal v-model="modalDesgloce" width="85%" :can-cancel="['x', 'outside']">
      <Desgloces v-model="interruptor" />
    </b-modal>
    <b-modal v-model="modalAgregar">
      <div class="modal-card" style="width: 100%;">
        <header class="modal-card-head p-2" style="">
          <p class="modal-card-title is-size-5">Nuevo Seguimiento</p>
        </header>

        <body class="modal-card-body">
          <section class="pb-4 is-flex is-justify-content-center" style="width: 100%">
            <div class="columns" style="width: 100%">
              <div class="column is-flex is-justify-content-center">
                <b-field>
                  <b-select size="is-small" v-model="servicio" rounded>
                    <option disabled value="">Selecciona un Servicio</option>
                    <option v-for="option in servicios" :value="option.id" :key="option.id">
                      {{ option.tipo }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-one-third" v-if="servicio">
                <b-field>
                  <b-select placeholder="Selecciona un ejecutivo" ref="select-ejecutivo" size="is-small" v-model="ejecutivo" rounded>
                    <option v-for="e in ejecutivos" :key="e.id" :value="e.nombre">{{ e.nombre }}</option>
                  </b-select>
                </b-field>

              </div>
            </div>

          </section>
          <Cotizacion2 ref="fieldCotizacion" v-if="(servicio == 6 || servicio == 7) && servicio != ''" v-model="servicio"
            :key="servicio" :ejecutivo="ejecutivo" />
          <Cotizacion1 ref="fieldCotizacion" v-if="(servicio != 6 && servicio != 7) && servicio != ''" v-model="servicio"
            :key="servicio" :ejecutivo="ejecutivo" />
        </body>
        <footer class="modal-card-foot is-flex is-justify-content-end p-2">
          <b-button :loading="!loading" v-if="servicio" size="is-small" label="Guardar" @click="guardarData()" />
        </footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'
import Cotizacion1 from '@/components/servicios/Cotizacion1'
import Cotizacion2 from '@/components/servicios/Cotizacion2'
import Desgloces from '@/views/Reservaciones'

export default {
  name: 'BotonesView',
  props: ['value'],
  components: {
    Cotizacion1,
    Cotizacion2,
    Desgloces
  },
  data () {
    return {
      servicios: [],
      servicio: '',
      modalAgregar: false,
      modalDesgloce: false,
      promesa: null,
      correos: [],
      email: '',
      loading: true,
      ejecutivos: [],
      ejecutivo: null
    }
  },
  methods: {
    message (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    },
    async obtenerEjecutivos () {
      this.ejecutivos = await SeguimientoService.obtenerEjecutivos()
    },
    async obtenerCorreos () {
      this.correos = await SeguimientoService.obtenerCorreos()
    },
    async guardarData () {
      const fieldsBotones = ['select-ejecutivo']
      for (const field of fieldsBotones) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      const fields = ['fieldAgencia', 'fieldFile']
      for (const field of fields) {
        if (!this.$refs.fieldCotizacion.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      if (this.$refs.fieldCotizacion.listCotizaciones <= 0) {
        this.message('No se ha registrado ninguna Cotizacion', 'is-warning')
        // eslint-disable-next-line
        return
      }
      this.loading = false
      const result = await this.$refs.fieldCotizacion.guardarCotizacion()
      if (result === true) {
        this.modalAgregar = false
        this.interruptor = !this.interruptor
        this.message('Cotizacion registrada con exito.', 'is-success')
        this.loading = false
      } else if (result === false) {
        this.message('Error: Hubo un error en la cotizacion', 'is-danger')
      } else {
        this.message('Error: ' + result, 'is-danger')
      }
    },
    async obtenerServicios () {
      this.servicios = await SeguimientoService.obtenerServicios()
    }
  },
  mounted () {
    this.obtenerServicios()
    this.obtenerCorreos()
    this.obtenerEjecutivos()
  },
  computed: {
    filtrarDataCorreos () {
      return this.correos.filter(option => {
        return (option.correo.toString().toLowerCase().indexOf(this.email.toLowerCase())) >= 0
      })
    },
    interruptor: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
