import HttpService from '@/services/HttpService'

const ArchivosService = {
  async subirArchivos (archivos, localizador) {
    const formData = new FormData()
    for (const archivo of archivos) {
      formData.append('archivos[]', archivo)
      formData.append('localizador', localizador)
    }
    return await HttpService.formdata('/subir_archivos.php', formData)
  },
  async generarReporte (usuario, fechas) {
    return await HttpService.post('/generarReporte.php', {
      usuario: usuario,
      fechas: fechas
    })
  }
}
export default ArchivosService
