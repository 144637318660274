import HttpService from '@/services/HttpService'

const UsuariosService = {
  async propagarSesionActiva (sesion) {
    return await HttpService.post('/propagarSesion.php', {
      sesion: sesion
    })
  },
  async consultarSesion () {
    return await HttpService.post('/obtenerSesion.php')
  },
  async login (usuario, password) {
    const data = {
      usuario: usuario,
      password: password
    }
    return await HttpService.post('/login.php', data)
  },
  async logout () {
    return await HttpService.post('/logout.php')
  }
}
export default UsuariosService
