<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import EventBus from '@/EventBus'

export default {

  name: 'App',
  components: {},
  mounted () {
    EventBus.$on('establecerUsuario', (usuario) => {
      this.refrescarUsuario(usuario)
    })
    EventBus.$on('cerrarSesion', () => {
      this.refrescarUsuario({})
    })
    EventBus.$on('navegarHacia', this.navegarHacia)
    EventBus.$on('mostrarMenu', this.mostrarMenu)
    EventBus.$on('ocultarMenu', this.ocultarMenu)
    this.obtenerUsuarioDeLocalStorage()
  },
  methods: {
    refrescarUsuario (usuario) {
      if (usuario.email) {
        this.logueado = true
        this.usuarioLogueado = usuario
      } else {
        this.logueado = false
      }
    },
    obtenerUsuarioDeLocalStorage () {
      const usuario = EventBus.obtenerUsuario()
      this.refrescarUsuario(usuario)
    },
    navegarHacia (nombreRuta) {
      this.$router.push({
        name: nombreRuta
      })
      this.menuEstaActivo = false
    },
    message (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    }
  }
}
</script>
<style>
html,
body.Login {
  height: 100%;
  background: linear-gradient(rgba(246, 149, 21, 0.340), rgba(246, 149, 21, 0.340)), url("@/assets/fondoLogin.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
html,
body.inicio {
  height: 100%;
    background: rgb(255, 255, 255);
}
</style>
