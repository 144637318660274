<template>
  <div>
    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" :open="isOpen">
      <template #trigger="props">
        <div class="card-header" role="button" aria-controls="contentIdForA11y3">
          <p class="card-header-title pb-1 pt-1" style="font-size: 12pt">
            Estadisticas
          </p>
          <a class="card-header-icon pb-1 pt-1">
            <b-icon :icon="props.open ? 'menu-up' : 'menu-down'">
            </b-icon>
          </a>
        </div>
      </template>

      <template>
        <article class="media">
          <div class="media-content">
            <div class="content p-5">
              <div class="rows">
                <div class="row is-flex is-justify-content-center mb-4">
                  <div class="columns is-center" style="width: 100%;">
                    <div class="column">
                      <b-field >
                        <b-select v-model="selectAgente" placeholder="Selecciona una Usuario" icon="account" validation-message="Porfavor, selecciona un agente.!"
                          @input="obtenerData(selectAgente, dateReporte)" size="is-small" ref="usuario" required>
                          <option value="general" selected>General</option>
                          <option v-for="agente in agentes" :key="agente.user" :value="agente.user">{{ agente.user }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-datepicker validation-message="Selecciona un rango de fechas!" size="is-small" v-model="dateReporte" placeholder="Selecciona Fecha de Inicio" ref="fechas"
                        @input="obtenerData(selectAgente, dateReporte)" icon="calendar-today" :icon-right="dateReporte.length > 0 ? 'close-circle' : ''" icon-right-clickable @icon-right-click="clearDate" range required/>
                      </b-field>
                    </div>
                    <div class="column is-flex is-justify-content-center">
                      <b-button size="is-small" type="is-success" @click="generarReporte(selectAgente, dateReporte)">Descargar Reporte</b-button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="column">
                  <Doughnut :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                    :width="width" :height="height" />
                </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </template>
      <footer class="card-footer">
      </footer>
    </b-collapse>

  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'
import SeguimientoService from '@/services/SeguimientoService'
import ArchivosService from '@/services/ArchivosService'
import Constantes from '@/Constantes'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 350
    },
    height: {
      type: Number,
      default: 350
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right'
          }
        }
      },
      isOpen: false,
      agentes: {},
      agente: 'general',
      selectAgente: null,
      dateReporte: [new Date(), new Date()]
    }
  },
  mounted () {
    this.obtenerAgentes()
    this.obtenerData(this.agente, this.restarFecha())
  },
  methods: {
    restarFecha () {
      const diaSiete = 6048 * 100000
      const fechaHoy = this.dateReporte[0].getTime()
      this.dateReporte[0].setTime(fechaHoy - diaSiete)
      return this.dateReporte
    },
    clearDate () {
      this.dateReporte = []
    },
    async generarReporte (usuario, fechas) {
      const fields = ['usuario']
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      if (this.$refs.fechas.dateSelected.length <= 0) {
        this.$refs.fechas.setInvalid()
      }
      const respuesta = await ArchivosService.generarReporte(usuario, fechas)
      if (respuesta.error === 200) {
        const url = Constantes.URL_SERVIDOR + '/descargaReporte.php?name=' + respuesta.nombre
        window.open(url)
      }
    },
    async obtenerAgentes () {
      const result = await SeguimientoService.obtenerAgentes()
      this.agentes = result
    },
    async obtenerData (agente, fechas) {
      console.log(fechas)
      const datos = await SeguimientoService.obtenerData(agente, fechas)
      this.definirData(datos)
    },
    async definirData (datos) {
      const labels = await SeguimientoService.obtenerLabels()
      for (let index = 0; index < labels.length; index++) {
        const estatus = datos.filter(respuesta => respuesta.estatus === labels[index].estatus)
        this.chartData.labels[index] = labels[index].Descripcion
        this.chartData.datasets[0].data[index] = estatus.length
        this.chartData.datasets[0].backgroundColor.push(labels[index].colorHex)
      }
    }
  }
}
</script>
