import HttpService from '@/services/HttpService'

const HotelServices = {
  async obtenerReserva (reserva) {
    return await HttpService.post('/obtenerReserva.php', {
      reserva: reserva
    })
  },
  async obtenerPagos () {
    return await HttpService.post('/formasPagos.php')
  },
  async generarFactura (reserva) {
    return await HttpService.post('/generarFactura.php', {
      reserva: reserva
    })
  },
  async saveData (data, comision, total, cxs, obs, destino, mp, serie, paquete) {
    return await HttpService.post('/saveData.php', {
      data: data,
      comision: comision,
      total: total,
      cxs,
      obs: obs,
      destino: destino,
      mp: mp,
      serie: serie,
      paquete: paquete
    })
  },
  async SubirComplemento (file, complemento, reserva, total) {
    const formdata = new FormData()
    formdata.append('img', file)
    formdata.append('complemento', complemento)
    formdata.append('reserva', reserva)
    formdata.append('total', total)
    return await HttpService.formdata('/saveComplementos.php', formdata)
  },
  async GuardarNewReporte (reserva, proveedor, cxs, cveAgencia, nomAgencia, fchin, fchout, hotel,
    titular, tPagado, moneda, nProveedor, nAgencia, mVenta, comisionAgencia, comision, mpago,
    fpago, viaje, serie, paquete) {
    return await HttpService.post('/GuardarHotelExt.php', {
      reserva: reserva,
      proveedor: proveedor,
      cxs: cxs,
      cveAgencia: cveAgencia,
      nomAgencia: nomAgencia,
      fchin: fchin,
      fchout: fchout,
      hotel: hotel,
      titular: titular,
      tPagado: tPagado,
      moneda: moneda,
      nProveedor: nProveedor,
      nAgencia: nAgencia,
      mVenta: mVenta,
      comisionAgencia: comisionAgencia,
      comision: comision,
      mpago: mpago,
      fpago: fpago,
      viaje: viaje,
      serie: serie,
      paquete: paquete
    })
  }
}
export default HotelServices
