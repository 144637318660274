<template>
  <div>
    <Header :userData="userData" :intervalId="intervalId" :timeOut="timeOut" />
    <div class="is-flex is-justify-content-center"><h1 style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; font-size: large;">
      Cerrando Sesion en: {{ timeOut }} Min</h1></div>
    <div class="section">
      <div class="columns">
        <div class=" column">
          <Grafica :key="subInterruptor" v-if="admin || !view" />
        </div>
        <div class="column is-flex is-justify-content-end is-align-items-end">
          <Botones v-model="interruptor" v-if="!view"/>
        </div>
      </div>
      <div class="box">
        <b-table :default-sort="['fch_registro', 'desc']" :paginated="listCotizaciones.length > 9" :per-page="pages"
          :data="isEmpty ? [] : listCotizaciones" mobile-cards narrowed detailed ref="table" detail-key="id"
          @details-open="(row) => closeAllOtherDetails(row)" :opened-detailed="defaultOpenedDetails"
          aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
          aria-current-label="Current page">

          <b-table-column field="id" label="Seguimiento" searchable width="40" numeric v-slot="props">
            <h3 style="font-size:80%">{{ props.row.id }}</h3>
          </b-table-column>
          <b-table-column field="ejecutivo" label="Ejecutivo" searchable width="100" v-slot="props">
            <h3 style="font-size:70%">{{ props.row.ejecutivo }}</h3>
          </b-table-column>
          <b-table-column field="user" label="Usuario" searchable width="40" v-slot="props">
            <h3 style="font-size:80%">{{ props.row.user }}</h3>
          </b-table-column>
          <b-table-column field="dateLimit" label="Fecha Limite" centered sortable v-slot="props">
            <b-tag type="is-light" v-if="props.row.dateLimit">
              <h3 style="font-size:80%">{{ props.row.dateLimit }}</h3>
            </b-tag>
          </b-table-column>

          <b-table-column field="cve_agencia" label="Clave Agencia" searchable centered sortable v-slot="props">
            <h3 style="font-size:80%">{{ props.row.cve_agencia }}</h3>
          </b-table-column>

          <b-table-column field="nom_agencia" label="Nombre Agencia" centered sortable v-slot="props">
            <h3 style="font-size:80%">{{ props.row.nom_agencia }}</h3>
          </b-table-column>

          <b-table-column field="tipo" label="Servicio" searchable sortable centered v-slot="props">
            <span class="tag is-info">
              <h3 style="font-size:80%">{{ props.row.tipo }}</h3>
            </span>
          </b-table-column>
          <b-table-column field="fch_registro" label="Fecha de Registro" searchable sortable centered v-slot="props">
            <h3 style="font-size:80%">{{ props.row.fch_registro }}</h3>
          </b-table-column>
          <b-table-column field="Descripcion" label="Estatus" sortable searchable centered v-slot="props">
            <b-tag :type="typeEstatus(props.row.estatus)">
              <h3 style="font-size:80%">{{ props.row.Descripcion }}</h3>
            </b-tag>
          </b-table-column>
          <template #detail="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <div class="columns">
                    <div class="column is-two-fifths">
                      <div class="rows">
                        <div class="row">
                          <h6 style="font-size: .9rem">DETALLES COTIZACION</h6>
                        </div>
                        <div class="row">
                          <DetalleCotizacion2 v-if="props.row.servicio == 6 || props.row.servicio == 7"
                            v-model="subInterruptor" :id="props.row.id" :servicio="props.row.servicio"
                            :estatus="props.row.estatus" :view="view" :key="subInterruptor" />
                          <DetalleCotizacion v-if="props.row.servicio != 6 && props.row.servicio != 7"
                            v-model="subInterruptor" :id="props.row.id" :servicio="props.row.servicio"
                            :estatus="props.row.estatus" :view="view" :key="subInterruptor" />
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-fifths">
                      <Historico v-model="props.row.id" :key="subInterruptor" />
                    </div>
                    <div class="column is-flex is-justify-content-center is-one-fifth">
                      <Estatus v-model="subInterruptor" class="mt-5" @recargar="obtenerCotizaciones()"
                        :id="props.row.id" :view="view" />
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </template>
          <template #empty>
            <div class="has-text-centered">No hay datos</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'
import DetalleCotizacion from '@/components/Detalles'
import DetalleCotizacion2 from '@/components/Detalles2'
import Historico from '@/components/Historico'
import Estatus from '@/components/Estatus'
import Grafica from '@/components/servicios/Graficas'
import Botones from '@/components/Botones'
import Header from '@/views/Header'
import EventBus from '@/EventBus'
import UsuariosService from '@/services/UsuariosService'

export default {
  beforeCreate: function () {
    document.body.className = 'Inicio'
  },
  name: 'ListaCotizaciones',
  props: ['value'],
  components: { DetalleCotizacion, DetalleCotizacion2, Historico, Estatus, Grafica, Botones, Header },
  data () {
    return {
      radio1: null,
      radio2: null,
      radio3: null,
      isEmpty: false,
      listCotizaciones: [],
      pages: 9,
      pagination: false,
      tagLimit: 'is-success',
      subInterruptor: false,
      userData: '',
      defaultSortDirection: 'desc',
      permisos: {},
      admin: false,
      view: false,
      defaultOpenedDetails: [],
      intervalId: null,
      timeOut: null
    }
  },
  mounted () {
    this.intervalId = setInterval(() => this.consultarSesion(), 1200)
    this.obtenerCotizaciones()
    this.obtenerPermisos()
  },
  beforeMount () {
    this.consultarSesion()
    this.validarSesion()
  },
  methods: {
    navegarHacia (nombreRuta) {
      this.$router.push({ name: nombreRuta })
      this.menuEstaActivo = false
    },
    async validarSesion () {
      const respuesta = await UsuariosService.consultarSesion()
      return respuesta
    },
    closeAllOtherDetails (row) {
      this.defaultOpenedDetails = [row.id]
    },
    determinarAdmin () {
      if (this.permisos.find(respuesta => respuesta.permiso === 'SEGUIMIENTO_ADMIN')) {
        this.admin = true
      }
    },
    determinarPermisoView () {
      if (this.permisos.find(respuesta => respuesta.permiso === 'SEGUIMIENTO_VIEW')) {
        this.view = true
      }

      console.log(this.view)
    },
    async obtenerPermisos () {
      const respuesta = await SeguimientoService.obtenerPermisos()
      this.permisos = respuesta
      this.determinarPermisoView()
      this.determinarAdmin()
    },
    async consultarSesion () {
      if (this.$router.history.current.name === 'Login') {
        clearInterval(this.intervalId)
        document.onmousemove = null
      } else {
        const time = new Date()
        const verificarSesionBack = await this.validarSesion()
        const consulta = EventBus.obtenerUsuario()
        document.onmousemove = async function () {
          this.userData = consulta
          EventBus.establecerUsuario(this.userData)
          if (verificarSesionBack === false) {
            // eslint-disable-next-line
            const propagar = await UsuariosService.propagarSesionActiva(this.userData)
            this.obtenerCotizaciones()
            this.obtenerPermisos()
          }
        }
        this.userData = consulta
        const validarTime = this.userData.time - time.getTime()
        this.timeOut = this.convertirHora(validarTime)
        if (validarTime < 0) {
          alert('Sesion inactiva, cerrando sesion')
          clearInterval(this.intervalId)
          document.onmousemove = null
          this.navegarHacia('Logout')
        }
      }
    },
    agregarCeroSiEsNecesario (valor) {
      if (valor < 10) {
        return '0' + valor
      } else {
        return '' + valor
      }
    },
    convertirHora (milisegundos) {
      const minutos = parseInt(milisegundos / 1000 / 60)
      milisegundos -= minutos * 60 * 1000
      const segundos = parseInt(milisegundos / 1000)
      return `${this.agregarCeroSiEsNecesario(minutos)}:${this.agregarCeroSiEsNecesario(segundos)}`
    },
    typeEstatus (estatus) {
      if (estatus === 1) {
        return 'is-primary'
      } else if (estatus === 2) {
        return 'is-success'
      } else if (estatus === 3) {
        return 'is-info'
      } else if (estatus === 4) {
        return 'is-danger'
      } else if (estatus === 5) {
        return 'is-warning'
      }
    },
    formatDate () {
      const d = new Date()
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return [year, month, day].join('-')
    },
    async obtenerCotizaciones () {
      // eslint-disable-next-line
      const respuesta = await SeguimientoService.obtenerCotizaciones()
      this.listCotizaciones = respuesta
      console.log(this.listCotizaciones)
    }
  },
  computed: {
    interruptor: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
.card-content {
  padding: .50rem;
}

.tooltip-content {
  font-size: .6rem !important;
}

.th-wrap {
  justify-content: center;
}
</style>
