import HttpService from '@/services/HttpService'

const AutoServices = {
  async obtenerProveedorSeguros () {
    return await HttpService.get('/obtenerProveedorSeguros.php')
  },
  async generarFactura (localizador) {
    return await HttpService.post('/generarFacturaAutos.php', {
      localizador: localizador
    })
  },
  async obtenerProveedor () {
    return await HttpService.get('/listProveedor.php')
  },

  async obtenerComision (proveedor) {
    return await HttpService.post('/listComision.php', {
      proveedor: proveedor
    })
  },
  async obtenerAgencias () {
    return await HttpService.get('/listAgencias.php')
  },
  async obtenerPagos () {
    return await HttpService.get('/listPagos.php')
  },

  async saveData (
    proveedor,
    neto,
    localizador,
    tipoCambio,
    concepto,
    agencia,
    monto,
    porcentComision,
    cargo,
    tipoPago,
    montoComisionable,
    noComisionable,
    comision,
    PRECIO_AGENCIA,
    totalPagado,
    destino,
    metodoPago,
    serie,
    checkIn,
    checkOut,
    observaciones,
    nomEjecutivo,
    paquete
  ) {
    return await HttpService.post('/saveDataAutos.php', {
      proveedor: proveedor,
      neto: neto,
      localizador: localizador,
      tipo_cambio: tipoCambio,
      concepto: concepto,
      agencia: agencia,
      monto: monto,
      porcentComision: porcentComision,
      cargo: cargo,
      tipoPago: tipoPago,
      monto_comisionable: montoComisionable,
      no_comisionable: noComisionable,
      comision: comision,
      PRECIO_AGENCIA: PRECIO_AGENCIA,
      totalPagado: totalPagado,
      destino: destino,
      metodoPago: metodoPago,
      serie: serie,
      checkIn: checkIn,
      checkOut: checkOut,
      observaciones: observaciones,
      nomEjecutivo: nomEjecutivo,
      paquete: paquete
    })
  },
  async uploadImage (file) {
    const formdata = new FormData()
    formdata.append('img', file)
    return await HttpService.formdata('/uploadImageAutos.php', formdata)
  }
}

export default AutoServices
