<template>
  <div>
    <b-table :data="listDetalles" ref="table" :paginated="listDetalles.length > 4" :row-class="getRowClass" per-page="4"
      pagination-position="top" pagination-simple>
      <b-table-column label="Proveedor" centered numeric v-slot="props">
        <p style="font-size:80%">{{ props.row.proveedor }}</p>
      </b-table-column>

      <b-table-column label="Destino" centered sortable v-slot="props">
        <p style="font-size:80%">{{ props.row.Destino }}</p>
      </b-table-column>

      <b-table-column label="Observaciones" centered sortable v-slot="props">
        <p style="font-size:80%">{{ props.row.Observaciones }}</p>
      </b-table-column>
      <b-table-column width="40" label="Accion" centered sortable v-slot="props" v-if="estatus <= 1">
        <b-button type="is-danger" size="is-small" style="font-size: 0.60rem;" icon-right="delete"
          @click="eliminarProveedor(props.row.id)" v-if="!view" />
      </b-table-column>
    </b-table>
    <div class="column is-flex is-justify-content-end" v-if="estatus <= 1">
      <b-button size="is-small" type="is-success" @click="isOpen = !isOpen" v-if="!view">
        <b-icon style="font-size: 1.2rem" icon="plus" size="is-small">
        </b-icon>
      </b-button>
    </div>
    <b-collapse class="card" animation="slide" :open="isOpen" @open="isOpen" >
      <div class="card-content">
        <div class="content">
          <div class="columns">
            <div class="column">
              <b-field label="proveedor">
                <b-autocomplete ref="fieldProv" size="is-small" v-model="proveedor" placeholder="Buscar Proveedor"
                  :data="filtrarDataProveedor" field="nombre" @select="option => (selected = option)" rounded
                  :required="check">
                  <template slot-scope="props">
                    <div class="media-content">
                      <p style="font-size: .6rem;">{{ props.option.nombre }}</p>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Destino">
                <b-input ref="fieldDestino" size="is-small" placeholder="Destino" v-model="destino" rounded required>
                </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Observaciones">
                <b-input ref="fieldObs" size="is-small" placeholder="Observaciones" v-model="observaciones" rounded
                  required>
                </b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Accion">
                <b-button type="is-info" style="font-size: .8rem;" icon-right="content-save-outline"
                  @click="saveProveedor(id)" />
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'

export default {
  name: 'listDetalles',
  props: ['value', 'servicio', 'estatus', 'id', 'view'],
  data () {
    return {
      listDetalles: [],
      modalProveedor: false,
      isOpen: false,
      proveedores: [],
      proveedor: '',
      destino: null,
      observaciones: null,
      check: true
    }
  },
  mounted () {
    this.obtenerCotizaciones(this.id)
    this.obtenerProveedores(this.servicio)
  },
  methods: {
    getRowClass (index) {
      if (index.id === this.listDetalles[0].id) {
        return 'is-selected has-background-info'
      }
    },
    async obtenerProveedores (servicio) {
      this.proveedores = await SeguimientoService.obtenerProveedores(servicio)
    },
    message (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    },
    async saveProveedor (id) {
      this.check = true
      const fields = ['fieldProv', 'fieldDestino', 'fieldObs']
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      const respuesta = await SeguimientoService.saveProveedor(id, this.proveedor, this.destino, this.observaciones)
      if (respuesta === true) {
        this.check = false
        this.subInterruptor = !this.subInterruptor
        this.message('Agregado con exito', 'is-success')
      } else if (respuesta.errorInfo) {
        this.message('Error: ' + respuesta.errorInfo[2], 'is-danger')
      } else {
        this.message('Error al agregar el proveedor', 'is-danger')
      }
    },
    async eliminarProveedor (id) {
      const respuesta = await SeguimientoService.eliminarProveedor(id)
      if (respuesta) {
        this.subInterruptor = !this.subInterruptor
        this.message('Se elimino proveedor', 'is-danger')
      } else if (respuesta.errorInfo) {
        this.message('Error: ' + respuesta.errorInfo[2], 'is-danger')
      } else {
        this.message('Error al eliminar', 'is-danger')
      }
    },
    async obtenerCotizaciones (id) {
      this.listDetalles = await SeguimientoService.obtenerDetalles(id)
    }
  },
  computed: {
    filtrarDataProveedor () {
      return this.proveedores.filter(option => {
        return (option.nombre.toString().toLowerCase().indexOf(this.proveedor.toLowerCase())) >= 0
      })
    },
    subInterruptor: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
.label {
  font-size: .8rem !important;
}

.dropdown-menu {
  min-width: 8.2rem !important;
}

.top {
  margin-bottom: 0rem !important;
}
</style>
