import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/Login'
import Inicio from '@/views/HomeView'
import Desgloces from '@/views/Reservaciones'
import Logout from '@/components/Logout'
import EventBus from './EventBus'

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/Desgloces',
      name: 'Desgloces',
      component: Desgloces
    },
    {
      path: '/',
      name: 'Login',
      component: Index
    },
    {
      path: '/Inicio',
      name: 'Inicio',
      component: Inicio
    },
    {
      path: '/Logout',
      name: 'Logout',
      component: Logout
    }

  ]
})
router.beforeEach((to, _from, next) => {
  if (to.path === '/' && EventBus.obtenerUsuario().email) {
    return next({ name: 'Inicio' })
  } else if (to.path === '/Inicio' && !EventBus.obtenerUsuario().email) {
    return next({ name: 'Login' })
  }
  return next()
})
export default router
