<template>
  <div class="card" style="height: 16rem !important; overflow-y: scroll">
    <header class="card-header p-0">
      <p class="card-header-title is-centered" style="font-size: 9pt">
        HISTORICO
      </p>
    </header>
    <div class="card-content">
      <div v-for="nota in listNotas" :key="nota.id" class="content" style="font-size: 9pt">
        <div class="columns">
          <b-tag class="historicoTag" type="is-info is-light">{{nota.user}}</b-tag>
          <div class="column is-flex is-justify-content-start">
            <p style="font-size: .8rem">{{ nota.nota }}</p>
          </div>
          <div class="column is-two-quarter is-flex is-justify-content-end" v-if="nota.nombre_archivo != null">
            <b-field>
            <b-tag
                class="archivotag"
                @click="descargarFile(nota.nombre_archivo, nota.archivo_encrypt)"
                ellipsis
                type="is-info">
                <p>{{ nota.nombre_archivo }}</p>
            </b-tag>
        </b-field>
          </div>
          <div class="column is-one-quarter">
            <p style="font-size: .7rem">{{ nota.date_registro }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'
import Constantes from '@/Constantes'

export default {
  name: 'HistoricoView',
  props: ['value'],
  data () {
    return {
      listNotas: []
    }
  },
  mounted () {
    this.obtenerNotas(this.id)
  },
  methods: {
    descargarFile (file, encrypt) {
      const url = Constantes.URL_SERVIDOR + '/descargarFile.php?name=' + file + '&encrypt=' + encrypt
      window.open(url)
    },
    async obtenerNotas (id) {
      this.listNotas = await SeguimientoService.obtenerNotas(id)
    }
  },
  computed: {
    id: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style>
.archivotag:hover {
  background-color: rgb(255, 255, 255) !important;
  color: #167df0 !important;
  border: 1px solid !important;
}
.historicoTag span{
  font-size: 0.65rem;
}
</style>
