<template>
  <div class="home">
    <section>
      <Cotizaciones v-model="interruptor" :key="interruptor" />
    </section>
  </div>
</template>
<script>
import SeguimientoService from '@/services/SeguimientoService'
import Cotizaciones from '@/components/Cotizaciones'
export default {
  name: 'HomeView',
  components: {
    Cotizaciones
  },
  data () {
    return {
      modalAgregar: false,
      servicios: [],
      servicio: '',
      interruptor: false
    }
  },
  mounted () {
    this.obtenerServicios()
  },
  methods: {
    async obtenerServicios () {
      this.servicios = await SeguimientoService.obtenerServicios()
    }
  }
}
</script>
