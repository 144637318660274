<template>
  <div class="section m-2">
    <b-modal v-model="modalActive" :can-cancel="true" @keypress.esc="calcularCambio()">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Calcular Tipo de Cambio</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field label="USD" horizontal>
                <b-numberinput v-model="USD" step="any" :controls="false" @keypress.enter.native="calcularCambio()" placeholder="Neto en Dolares" />
              </b-field>
            </div>
            <div class="column">
              <b-field label="MXN" horizontal>
                <b-numberinput v-model="MXN" step="any" :controls="false" @keypress.enter.native="calcularCambio()" placeholder="Neto en Pesos" />
              </b-field>
            </div>
            <div class="column is-one-quarter is-flex is-justify-">
              <b-button label="Cacular" type="is-primary" @click="calcularCambio()" />
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <div class="is-flex is-justify-content-center" width="100%">
      <h1 class="title">RENTA DE AUTOS</h1>
    </div>
    <div class="columns pt-6">
      <div class="column" v-if="logoAutos != null">
        <b-field>
            <img class="image" ratio="3by1" :src="require('@/assets/' + logoAutos + '.png')" />
        </b-field>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-end">
        <b-field :label="updateLabel()" align="center" style="width: 100%;">
          <b-autocomplete size="is-small" ref="fieldProveedor" v-model="proveedor" :data="filtrarProveedores"  icon="account-supervisor" placeholder="Escribe el nombre del proveedor"
          field="proveedor" @select="(option) => obtenerComision(option)" rounded>
          <template slot-scope="props">
            <div  class="media">
              <div class="media-content">
                {{ props.option.proveedor + " - " + props.option.tipo }}
              </div>
            </div>
          </template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-end" v-if="proveedor != ''">
        <b-field label="Localizador" style="width: 100%;">
          <b-input size="is-small" ref="fieldLoc" v-model="localizador" type="text" placeholder="Escribe el Localizador de la reserva" rounded required />
        </b-field>
      </div>
    </div>
    <div class="columns" v-if="(nomAgencia != '' || agencia != '') && (localizador != null && localizador != '')">
      <div class="column is-flex is-justify-content-center">{{agencia + " - " + nomAgencia}}</div>
    </div>
    <div class="columns" v-if="localizador != null && localizador != ''">
      <div class="column">
        <b-field>
          <b-autocomplete size="is-small" rounded v-model="agencia" :data="filtrarAgencias" clearable icon="account-tie-hat" field="NUM_CLIENTE" placeholder="Clave de Agencia"
          @select="(option) => mandarDatoAgencia(option)">
          <template slot-scope="props">
            <div class="media">
              <div  class="media-content">
                {{ props.option.NUM_CLIENTE + " - " + props.option.NOMBRE }}
              </div>
            </div>
          </template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column">
        <b-field style="width: 100%;">
          <b-select expanded size="is-small" v-model="pago" ref="fieldPagos" required rounded>
            <option disabled value="">Selecciona Forma de Pago</option>
            <option v-for="pago in pagos" :value="pago.key" :key="pago.id">
              {{ pago.descripcion }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-select size="is-small" v-model="metodoPago" ref="fieldMP" required rounded expanded>
          <option disabled value="">Selecciona Metodo de pago</option>
          <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
          <option value="PUE">PAGO EN UNA SOLA EXHIBICION</option>
        </b-select>
      </div>
      <div class="column">
        <b-field>
          <b-select size="is-small" v-model="serie" rounded required ref="fieldSerie" expanded>
            <option disabled value="">Selecciona una Forma de Factura</option>
            <option value="FAI3">FAI3 - Agencia</option>
            <option value="FEI3">FEI3 - Empresarial</option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns" v-if="localizador != null && localizador != ''">
      <div class="column">
        <b-field class="is-small">
          <b-datepicker size="is-small" position="is-top-right" placeholder="Selecciona fecha de CheckIn" icon="calendar" v-model="checkIn"
           ref="fieldCheckIn" rounded required />
        </b-field>
      </div>
      <div class="column">
        <b-datepicker size="is-small" position="is-top-right" placeholder="Selecciona fecha de CheckOut" icon="calendar"
        v-model="checkOut" ref="fieldCheckOut" rounded required />
      </div>
      <div class="column">
        <b-field>
          <b-input size="is-small"  ref="fieldConcept" v-model="concepto" type="text" placeholder="Concepto" rounded>
          </b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field class="is-flex is-justify-content-center">
          <b-button :label="tipoCambio || 'Calcular tipo de Cambio'" rounded size="is-small" icon-right="calculator" type="is-warning is-light" @click="modalActive = true">
          </b-button>
        </b-field>
      </div>
    </div>
    <div class="columns" v-if="localizador != null && localizador != ''">
      <div class="column is-flex is-align-items-end is-justify-content-center" v-if="tipoProveedor == 'DIRECTO'">
        <b-field style="width: 100%;">
          <b-numberinput expanded size="is-small" ref="fieldComision" :controls="false" step="any" v-model="comision" placeholder="Ingresa comision del Proveedor" required rounded />
        </b-field>
      </div>
      <div class="column is-flex is-align-items-end is-justify-content-center" v-if="tipoProveedor != 'DIRECTO'">
        <b-field custom-class="is-size-6 " label-position="on-border" :label="(comision || 0)+ '%'" class="is-flex is-justify-content-center">
          <b-select class="pt-1" size="is-small" v-model="comision" ref="fieldComision" @input="calcularMontos()" required rounded expanded
          placeholder="Selecciona la comision del Proveedor">
            <option v-for="option in listaComision" :value="option.comision" :key="option.id">
              {{option.tipo_destino + " / " + option.tipo_pago + " / " + option.tipo_comision}}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-flex is-align-items-end">
        <b-field style="width: 100%;">
          <b-numberinput size="is-small" ref="fieldMonto" :controls="false" step="any" v-model="monto" @input="calcularMontos()" placeholder="Monto de Venta en MXN"
          rounded required expanded />
        </b-field>
      </div>
      <div class="column is-flex is-align-items-end">
        <b-field style="width: 100%;">
          <b-numberinput step="any" size="is-small" v-model="neto" :controls="false" ref="fieldNeto" placeholder="Escribe el neto en MXN" rounded required />
        </b-field>
      </div>
      <div class="column is-flex is-align-items-end is-justify-content-center">
        <b-field label="Cargos por Servicio" custom-class="has-text-centered">
          <span class="tag is-medium"  v-if="status_cxs <= 0">
          <label class="pr-2">CONTRA REPORTE</label>
        </span>
        <b-numberinput step="any" size="is-small" :controls="false"  placeholder="Introduce valor" v-if="status_cxs" v-model="valor_cxs" type="is-light" @input="calcularMontos()" />
        <b-tag @click.native="cambiarValor()" type="is-info" size="is-medium">
          <b-icon :icon="icon_cxs" size="is-small"></b-icon>
        </b-tag>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-flex is-align-items-end is-justify-content-center">
        <b-field style="width: 100%;" label="Impuestos" custom-class="has-text-centered">
          <span style="width: 100%;" class="tag is-medium pl-6" v-if="statusImpuesto <= 0">
            <label class="pr-2">{{porcentajeImpuesto}} %</label>
          </span>
          <b-numberinput step="any" expanded size="is-small" :controls="false"  placeholder="Introduce valor" v-if="statusImpuesto" v-model="porcentajeImpuesto" type="is-light" @input="calcularMontos()" />
          <b-tag @click.native="cambiarValorImpuesto()" type="is-info" size="is-medium">
            <b-icon :icon="iconImpuesto" size="is-small"></b-icon>
          </b-tag>
        </b-field>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-end">
        <b-field grouped group-multiline>
          <div class="control" style="width: 100%;">
            <b-taglist attached style="flex-wrap: unset;">
              <b-tag style="width: 100%; font-size: 1rem" type="has-text-info has-text-white">Comision Agencia:</b-tag>
              <b-tag style="width: 50%; font-size: 1rem" type="is-info">{{totalComisionAgencia}}</b-tag>
            </b-taglist>
          </div>
        </b-field>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-end">
        <b-field grouped group-multiline>
          <div class="control" style="width: 100%;">
            <b-taglist attached style="flex-wrap: unset;">
              <b-tag style="width: 100%; font-size: 1rem" type="has-text-info has-text-white">Total Neto:</b-tag>
              <b-tag style="width: 50%; font-size: 1rem" type="is-info">{{netoTotal}}</b-tag>
            </b-taglist>
          </div>
        </b-field>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-end">
        <b-field grouped group-multiline>
          <div class="control" style="width: 100%;">
            <b-taglist attached style="flex-wrap: unset;">
              <b-tag style="width: 100%; font-size: 1rem" type="has-text-info has-text-white">Comisionable:</b-tag>
              <b-tag style="width: 50%; font-size: 1rem" type="is-info">{{montoComisionable}}</b-tag>
            </b-taglist>
          </div>
        </b-field>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-end">
        <b-field grouped group-multiline>
          <div class="control" style="width: 100%;">
            <b-taglist attached style="flex-wrap: unset;">
              <b-tag size="is-medium" style="width: 100%; font-size: 1rem" type="has-text-info has-text-white">No Comisionable:</b-tag>
              <b-tag style="width: 50%; font-size: 1rem" type="is-info"  >{{impuestoNeto}}</b-tag>
            </b-taglist>
          </div>
        </b-field>
      </div>
    </div>
    <div>

    </div>
  </div >
</template>
<script>
import AutoServices from '@/services/AutoServices'
export default {
  name: 'AutosComponent',
  data () {
    return {
      logoAutos: null,
      proveedores: [],
      proveedor: '',
      tipoProveedor: '',
      listaComision: [],
      comision: null,
      localizador: null,
      checkIn: null,
      checkOut: null,
      agencias: [],
      agencia: '',
      nomAgencia: '',
      pagos: [],
      pago: '',
      metodoPago: '',
      serie: '',
      observaciones: null,
      concepto: null,
      modalActive: false,
      USD: null,
      MXN: null,
      tipoCambio: null,
      monto: null,
      neto: null,
      valor_cxs: 'CONTRA REPORTE',
      icon_cxs: 'lead-pencil',
      status_cxs: false,
      porcentajeImpuesto: 25,
      impuestoNeto: null,
      montoComisionable: null,
      iconImpuesto: 'lead-pencil',
      statusImpuesto: false,
      disabledImpuesto: false,
      totalComisionAgencia: null,
      netoTotal: null,
      totalNeto: null,
      totalPagar: null,
      totalPagado: null
    }
  },
  methods: {
    calcularMontos () {
      const convertImpuesto = this.porcentajeImpuesto / 100
      this.impuestoNeto = (this.monto * convertImpuesto).toFixed(2)
      this.montoComisionable = (this.monto - this.impuestoNeto).toFixed(2)
      this.totalComisionAgencia = (this.montoComisionable * (this.comision / 100)).toFixed(2)
      this.netoTotal = (this.montoComisionable - this.totalComisionAgencia + parseFloat(this.impuestoNeto)).toFixed(2)
      this.calcularTotalAPagar()
    },
    calcularTotalAPagar () {
      if ((this.valor_cxs === 'CONTRA REPORTE' || this.valor_cxs == null) && this.netoTotal != null) {
        this.totalPagar = this.netoTotal
      } else if (this.valor_cxs != null && this.netoTotal === 0) {
        this.totalPagar = this.this.valor_cxs
      } else {
        this.totalPagar = (parseFloat(this.valor_cxs) + parseFloat(this.netoTotal)).toFixed(2)
      }
    },
    cambiarValorImpuesto () {
      this.statusImpuesto = !this.statusImpuesto
      if (this.statusImpuesto === true) {
        this.iconImpuesto = 'close-circle-outline'
        this.porcentajeImpuesto = null
        this.disabledImpuesto = false
      } else {
        this.iconImpuesto = 'lead-pencil'
        this.porcentajeImpuesto = 25
        this.calcularMontos()
        this.disabledImpuesto = true
      }
    },
    cambiarValor () {
      this.status_cxs = !this.status_cxs
      if (this.status_cxs === true) {
        this.icon_cxs = 'close-circle-outline'
        this.valor_cxs = null
      } else {
        this.icon_cxs = 'lead-pencil'
        this.valor_cxs = 'CONTRA REPORTE'
        this.calcularMontos()
      }
    },
    calcularCambio () {
      this.tipoCambio = parseFloat((this.MXN / this.USD).toFixed(2)).toString()
      this.modalActive = false
    },
    mandarDatoAgencia (cveAgencia) {
      if (cveAgencia === null) {
        this.agencia = ''
        this.nomAgencia = ''
      } else {
        this.agencia = cveAgencia.NUM_CLIENTE
        this.nomAgencia = cveAgencia.NOMBRE
      }
    },
    async obtenerProveedores () {
      const respuesta = await AutoServices.obtenerProveedor()
      this.proveedores = respuesta
    },
    async obtenerComision (option) {
      if (option != null) {
        this.tipoProveedor = option.tipo
        console.log(this.tipoProveedor)
        const respuesta = await AutoServices.obtenerComision(option.id)
        this.listaComision = respuesta
        if (option.tipo === 'DIRECTO') {
          this.logoAutos = option.proveedor
        } else {
          this.logoAutos = option.tipo
        }
      }
    },
    updateLabel () {
      if (this.proveedor === '' || this.tipoProveedor === '') {
        this.logoAutos = null
        return ''
      } else {
        return this.proveedor + '-' + this.tipoProveedor
      }
    },
    async obtenerAgencias () {
      this.agencias = await AutoServices.obtenerAgencias()
    },
    async obtenerPagos () {
      this.pagos = await AutoServices.obtenerPagos()
    }
  },
  mounted () {
    this.obtenerProveedores()
    this.obtenerAgencias()
    this.obtenerPagos()
  },
  computed: {
    filtrarProveedores () {
      return this.proveedores.filter((option) => {
        return (option.proveedor.toString().toLowerCase().indexOf(this.proveedor.toLowerCase())) >= 0 || (option.tipo.toString().toLowerCase().indexOf(this.proveedor.toLowerCase()) >= 0
        )
      })
    },
    filtrarAgencias () {
      return this.agencias.filter((option) => {
        return (option.NUM_CLIENTE.toString().toLowerCase().indexOf(this.agencia.toLowerCase())) >= 0 || (option.NOMBRE.toString().toLowerCase().indexOf(this.agencia.toLowerCase()) >= 0
        )
      })
    }
  }
}
</script>
<style>
.field.is-floating-label .label, .field.is-floating-in-label .label {
left:unset !important;
}

.numberButton .input{
  border-radius: 10rem 0rem 0rem 10rem !important;
}
</style>
