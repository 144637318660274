import HttpService from '@/services/HttpService'
const ProveedoresService = {
  async obtenerProveedores () {
    return await HttpService.post('/obtener_Proveedores.php')
  },
  async obtenerProveedor (Dato) {
    const cargaUtil = { Dato }
    return await HttpService.post('/obtenerProveedor.php', cargaUtil)
  },
  async obtenerEjecutivos () {
    return await HttpService.post('/obtener_Ejecutivos.php')
  },
  async obtenerEjecutivo (Dato) {
    const cargaUtil = { Dato }
    return await HttpService.post('/obtenerEjecutivo.php', cargaUtil)
  },
  async obtenerProvHoteles () {
    return await HttpService.get('/obtenerProveedorHoteles.php')
  }
}
export default ProveedoresService
