<template>
    <b-navbar class="p-1" style="background-image: linear-gradient(to right, rgb(236, 132, 0) , rgb(53, 34, 147));">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="@/assets/logo_contravel_dark.png" style="  max-height: 4rem;"
                    alt="Contravel">
            </b-navbar-item>
        </template>
        <template #end>
            <b-navbar-item tag="div" style="color: #ffff;" class="is-flex is-justify-content-center">
                <strong style="color:#ffff">Bienvenido: </strong> &nbsp; {{ userData.firstName }}&nbsp;{{ userData.lastName }}
            </b-navbar-item>
            <b-navbar-item tag="div" class="is-flex is-justify-content-center">
                <div class="buttons">
                    <b-button type="is-white" :loading="cargando" size="is-small" icon-left="logout" @click="cerrarSesion()" outlined rounded>Salir
                    </b-button>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>
<script>
export default {
  name: 'HeaderCotizaciones',
  props: ['userData', 'intervalId', 'timeOut'],
  data () {
    return {
      cargando: false
    }
  },
  methods: {
    cerrarSesion () {
      clearInterval(this.intervalId)
      document.onmousemove = null
      this.cargando = true
      this.navegarHacia('Logout')
    },
    navegarHacia (name) {
      this.$router.push({
        name: name
      })
    }
  }
}
</script>
<style>
nav {
    padding: 10px !important;
}

@media screen and (max-width: 1023px){
  .navbar-menu {
    background-color: unset !important;
    padding: 0rem !important;
}
  }
.navbar-brand, .navbar-tabs {
  align-items: center !important;
}
.navbar-burger span {
  background-color: white !important;
}
</style>
