<template>
<div class="section m-1">
  <div class="is-flex is-justify-content-center" width="100%">
    <h1 class="title">RESERVACIONES EXTERNAS HOTELES</h1>
  </div>
  <div class="columns pt-6">
    <div class="column" v-if="proveedor != null">
      <img class="image" ratio="3by1" :src="verificarImg(proveedor)" />
    </div>
    <div class="column is-flex is-justify-content-center is-align-items-end">
      <b-field style="width: 100%;">
        <b-select placeholder="Selecciona un proveedor" v-model="proveedor" ref="fieldProveedor" required rounded expanded size="is-small">
          <option v-for="proveedor in proveedores" :key="proveedor.id" :value="proveedor.nombre">{{proveedor.nombre}}</option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-flex is-justify-content-center is-align-items-end" v-if="proveedor != null">
      <b-field style="width: 100%">
        <b-input size="is-small" rounded ref="fieldReserva" v-model="reserva" placeholder="Ingresa No. Reservación" type="is-light" required />
      </b-field>
    </div>
  </div>
  <div class="columns" v-if="nom_agencia != '' || agencia != ''">
    <div class="column is-flex is-justify-content-center">{{agencia + " - " + nom_agencia}}</div>
  </div>
  <div class="columns" v-if="reserva != null && reserva != ''">
    <div class="column">
      <b-field>
        <b-autocomplete size="is-small" v-model="agencia" ref="fieldAgencia" field="NUM_CLIENTE" required rounded placeholder="Agencia"
        :data="filtrarDataAgencias" @select="option => MandarDatoAgencias(option)" open-on-focus>
        <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.NUM_CLIENTE + " - " + props.option.NOMBRE}}
                  <br />
                </div>
              </div>
            </template>
      </b-autocomplete>
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <b-select expanded size="is-small" rounded ref="fieldMoneda" placeholder="Tipo de moneda" v-model="moneda" required>
          <option value="MXN">MXN</option>
          <option value="USD">USD</option>
        </b-select>
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <b-select expanded size="is-small" rounded ref="fieldViaje" placeholder="Tipo de viaje" v-model="viaje" required>
          <option value="Nacional">Nacional</option>
          <option value="Internacional">Internacional</option>
        </b-select>
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <b-select expanded size="is-small" rounded ref="fieldSerie" placeholder="Serie Factura" v-model="serie" required>
          <option value="FAI3">FAI3 - Agencias</option>
          <option value="FEI3">FEI3 - Empresas</option>
        </b-select>
      </b-field>
    </div>
  </div>
  <div class="columns" v-if="reserva != null && reserva != ''">
    <div class="column">
      <b-field>
        <b-input rounded size="is-small" style="text-transform:uppercase;" v-model="titular" ref="fieldTitular" placeholder="Nombre del titular" required />
      </b-field>
    </div>
    <div class="column">
      <b-input rounded size="is-small" ref="fieldHotel" v-model="hotel" placeholder="Nombre del hotel" required />
    </div>
    <div class="column">
      <b-field>
        <b-datepicker rounded size="is-small" ref="fieldIn" v-model="dateIn" :show-week-number="true" placeholder="Selecciona Fecha de Entrada"
        icon="calendar-today" :icon-right="dateIn ? 'close-circle-outline' : ''" icon-right-clickable @icon-right-click="dateIn = null"
        position="is-top-right" trap-focus required />
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <b-datepicker rounded size="is-small" ref="fieldOut" v-model="dateOut" :show-week-number="true" placeholder="Selecciona Fecha de Salida"
        icon="calendar-today" :icon-right="dateOut ? 'close-circle-outline' : ''" icon-right-clickable @icon-right-click="dateOut = null"
        position="is-top-right" trap-focus required />
      </b-field>
    </div>
  </div>
  <div class="columns" v-if="reserva != null && reserva != ''">
    <div class="column">
      <b-field>
        <b-numberinput   rounded size="is-small" ref="fieldNetoProveedor" :controls="false" placeholder="Introduce el neto proveedor" v-model="netoProveedor" step="0.01"
        aria-minus-label="Decrement by 0.01" aria-plus-label="Increment by 0.01" type="is-light" @input="realizaroperacion()" required />
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <b-select size="is-small" rounded ref="fieldMetodoPago" placeholder="Selecciona metodo de pago" v-model="mpago" required expanded>
          <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
                <option value="PUE">PAGO EN UNA SOLA EXHIBICIÓN</option>
        </b-select>
      </b-field>
    </div>
    <div class="column">
      <b-field>
        <b-select size="is-small" v-model="fpago" ref="fieldFormaPago"  placeholder="Selecciona Forma de pago" required rounded expanded>
          <option v-for="pago_option in this.forma_pago" :value="pago_option.key"  :key="pago_option.id">{{ pago_option.descripcion }}</option>
        </b-select>
      </b-field>
    </div>
  </div>
  <div class="columns" v-if="netoProveedor > 0&&reserva != 0" >
    <div class="column is-flex is-justify-content-center is-align-items-center">
      <label class=" pr-2">Cargo por servicios: </label>
      <span class="tag is-medium"  v-if="status_cxs <= 0">
        <label class="pr-2">CONTRA REPORTE</label>
      </span>
      <b-numberinput size="is-small" :controls="false"  placeholder="Introduce valor" v-if="status_cxs" v-model="valor_cxs" type="is-light" @input="realizaroperacion()" />
      <b-tag @click.native="cambiarValor()" type="is-info" size="is-medium">
        <b-icon :icon="icon" size="is-small"></b-icon>
      </b-tag>
    </div>
    <div class="column  is-flex is-justify-content-center is-align-items-center">
      <label class=" pr-2">Porcentaje Agencia: </label>
      <span class="tag is-medium"  v-if="status_agen <= 0">
        <label class="pr-2">.92 (Por default)</label>
      </span>
      <b-numberinput size="is-small" :controls="false"  placeholder="Introduce Porcentaje agencia" v-if="status_agen" v-model="porcentajeAgen" type="is-light"
       @input="realizaroperacion()" />
      <b-tag @click.native="cambiarValor3()" type="is-info" size="is-medium">
        <b-icon :icon="icon_agencia" size="is-small"></b-icon>
      </b-tag>
    </div>
    <div class="column  is-flex is-justify-content-center is-align-items-center">
      <label class=" pr-2">{{porcentaje}}% de comision: </label>
      <span class="tag is-medium"  v-if="status_comi <= 0">
        <label class="pr-2">SIN_COMISION</label>
      </span>
      <b-numberinput size="is-small" :controls="false"  placeholder="Introduce Porcentaje " v-if="status_comi" v-model="porcentaje" type="is-light"
       @input="realizaroperacion()" />
      <b-tag @click.native="cambiarValor2()" type="is-info" size="is-medium">
        <b-icon :icon="icon_comi" size="is-small"></b-icon>
      </b-tag>
    </div>
    <div class="column is-flex" v-if="porcentaje > 0">
      <b-checkbox type="is-warning" v-model="checkbox" @input="realizaroperacion()">
        <strong> <p>Calcular comision al Total</p></strong>
      </b-checkbox>
    </div>
  </div>
  <div class="columns pt-6" v-if="desglose&&netoProveedor > 0&&reserva != 0">
    <div class="column is-flex is-flex-direction-column is-flex is-justify-content-center">
      <div class="columns">
        <div class="column">
          <div class="row is-flex is-flex-direction-column is-align-items-center">
            <strong> <p>Precio Neto Agencia:</p></strong>
            <span class="tag is-medium"> $ &nbsp; {{ netoAgencia }} </span>
          </div>
        </div>
        <div class="column">
          <div class="row is-flex is-flex-direction-column  is-align-items-center">
          <strong> <p>Comisión Agencia: </p></strong>
          <span class="tag is-medium">$ &nbsp; {{ comisionAgencia }} </span>
        </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="row is-flex is-flex-direction-column is-align-items-center">
            <strong> <p>Monto Venta:</p></strong>
            <span class="tag is-medium"> $ &nbsp; {{ m_venta }} </span>
          </div>
        </div>
        <div class="column">
          <div class="row is-flex is-flex-direction-column is-align-items-center">
            <strong> <p>Precio a pagar:</p></strong>
            <span class="tag is-medium"> $ &nbsp; {{ t_pagado }} </span>
          </div>
        </div>
      </div>
      <div class="column is-flex is-justify-content-center is-align-items-center pt-3" v-if="mpago == 'PUE'">
        <strong class="pr-2">TOTAL PAGADO: </strong>
        <span class="tag is-large"> $ &nbsp; {{ t_pagado }}</span>
      </div>
      <div class="column is-flex is-justify-content-center pt-3" v-if="mpago == 'PPD'">
        <strong class="pr-2">TOTAL PAGADO: </strong>
        <b-field>
          <b-numberinput rounded size="is-small" :controls="false" ref="fieldTotalPagado" placeholder="Introduce Primero pago de la Agencia" step="0.01"
          v-model="totalComplemento" required/>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Observaciones">
          <b-input maxlength="1000" rows="2" type="textarea" v-model="observaciones" />
        </b-field>
      </div>
    </div>
    <div class="column">
      <div class="rows">
        <div class="row is-flex is-flex-direction-column is-align-items-center pb-5" v-if="!avatar">
          <b-field class="file is-info">
              <b-upload   ref="avatarInput" accept="image/gif,image/jpeg,image/jpg,image/png" @input="changeImage($event)" >
                <span class="file-cta">
                  <span class="file-label">Cargar imagen del Comprobante de Pago</span>
                  <b-tooltip label="Subir Comprobante de Pago" position="is-bottom" type="is-info is-light" size="is-small">
                    <b-icon class="file-icon pl-4" icon="upload"></b-icon>
                  </b-tooltip>
                </span>
              </b-upload>
          </b-field>
        </div>
        <div class="row is-flex is-flex-direction-column is-align-items-center pb-6" v-if="avatar">
          <div class="rows" style="width: 100%">
            <div class="row is-flex is-flex-direction-column is-align-items-end">
              <a @click="eliminarview()">
              <b-icon icon="close-circle-outline"> </b-icon>
            </a>
            </div>
            <div class="row is-flex is-flex-direction-column is-align-items-center">
              <img :src="avatar" style="width: 25vw" />
            </div>
          </div>
        </div>
      </div>
      <div class="rows">
        <div class="row">
          <b-field class="is-flex is-justify-content-center p-0">
            <b-upload v-model="dropFiles" multiple drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>
                    Suelte sus archivos para cargar invoice!
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags is-centered">
            <span v-for="(files, index) in dropFiles" :key="index" class="tag is-primary">
              {{ files.name }}
              <button class="delete is-small" type="button" @click="deleteDropFile(index)" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-button @click="confirmCustom()" size="is-medium" v-if="t_pagado != null&& netoProveedor > 0" class="save" icon-left="content-save"
      :loading="loading" rounded />
</div>
</template>
<script>
import ArchivosService from '@/services/ArchivosService'
import AutoServices from '@/services/AutoServices'
import HotelServices from '@/services/HotelServices'
import ProveedoresService from '@/services/ProveedoresService'
import SeguimientoService from '@/services/SeguimientoService'

export default {
  name: 'HotelExtComponent',
  data () {
    return {
      proveedor: null,
      reserva: null,
      proveedores: [],
      cargando: false,
      agencias: [],
      agencia: '',
      nom_agencia: '',
      moneda: null,
      viaje: null,
      serie: null,
      titular: null,
      hotel: null,
      dateIn: null,
      dateOut: null,
      netoProveedor: null,
      mpago: null,
      fpago: null,
      forma_pago: [],
      icon: 'lead-pencil',
      status_cxs: false,
      valor_cxs: null,
      status_agen: false,
      icon_agencia: 'lead-pencil',
      icon_comi: 'lead-pencil',
      status_comi: null,
      porcentaje: 0,
      porcentajeAgen: 92,
      checkbox: false,
      netoAgencia: null,
      comisionAgencia: null,
      m_venta: null,
      n_venta: null,
      t_pagado: null,
      avatar: '',
      desglose: false,
      dropFiles: [],
      files: {},
      loading: false,
      totalComplemento: null,
      observaciones: null
    }
  },
  mounted () {
    this.obtenerProveedores()
    this.obtenerAgencias()
    this.obtenerPagos()
  },
  methods: {
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1)
    },
    verificarImg (img) {
      let url = null
      try {
        url = require('@/assets/' + img + '.png')
      } catch (e) {
        url = require('@/assets/OTROS.png')
      }
      return url
    },
    async obtenerProveedores () {
      this.proveedores = await ProveedoresService.obtenerProvHoteles()
    },
    async MandarDatoAgencias (cveAgencia) {
      if (cveAgencia === null) {
        this.agencia = ''
        this.nom_agencia = ''
      } else {
        this.agencia = cveAgencia.NUM_CLIENTE
        this.nom_agencia = cveAgencia.NOMBRE
      }
    },
    async obtenerAgencias () {
      this.cargando = true
      this.agencias = await SeguimientoService.obtenerAgencias()
      this.cargando = false
    },
    async obtenerPagos () {
      this.forma_pago = await AutoServices.obtenerPagos()
    },
    cambiarValor () {
      this.status_cxs = !this.status_cxs
      if (this.status_cxs === true) {
        this.icon = 'close-circle-outline'
        this.valor_cxs = 0
      } else {
        this.valor_cxs = 0
        this.icon = 'lead-pencil'
      }
    },
    cambiarValor2 () {
      this.status_comi = !this.status_comi
      if (this.status_comi === true) {
        this.icon_comi = 'close-circle-outline'
        this.porcentaje = 0
      } else {
        this.porcentaje = 0
        this.icon_comi = 'lead-pencil'
      }
      this.realizaroperacion()
    },
    cambiarValor3 () {
      this.status_agen = !this.status_agen
      if (this.status_agen === true) {
        this.icon_agencia = 'close-circle-outline'
        this.porcentajeAgen = 92
      } else {
        this.porcentajeAgen = 92
        this.icon_agencia = 'lead-pencil'
      }
      this.realizaroperacion()
    },
    realizaroperacion () {
      let porcentA
      if (this.porcentajeAgen === 0) {
        porcentA = 1
      } else {
        porcentA = this.porcentajeAgen / 100
      }
      this.netoAgencia = parseFloat((this.netoProveedor / porcentA).toFixed(2))
      this.comisionAgencia = parseFloat(((this.netoAgencia * this.porcentaje) / 100).toFixed(2))
      this.m_venta = (this.comisionAgencia + this.netoAgencia).toFixed(2)
      if (this.checkbox === true) {
        this.t_pagado = (this.valor_cxs + this.netoAgencia + this.comisionAgencia).toFixed(2)
      } else {
        if (this.valor_cxs > 0) {
          this.t_pagado = (this.netoAgencia + this.valor_cxs).toFixed(2)
        } else {
          this.t_pagado = (this.netoAgencia).toFixed(2)
        }
      }
      this.desglose = true
    },
    eliminarview () {
      this.avatar = null
      this.file = ''
    },
    changeImage (e) {
      this.file = e
      const reader = new FileReader()
      const that = this
      reader.readAsDataURL(this.file)
      reader.onload = function (e) {
        that.avatar = this.result
      }
    },
    confirmCustom () {
      const fields = [
        'fieldProveedor', 'fieldReserva', 'fieldAgencia', 'fieldMoneda', 'fieldViaje',
        'fieldSerie', 'fieldTitular', 'fieldViaje', 'fieldIn', 'fieldOut', 'fieldMetodoPago', 'fieldFormaPago'
      ]
      if (this.mpago === 'PPD') {
        fields.push('fieldTotalPagado')
      }
      console.log(fields)
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return
        }
      }
      if (!this.file && this.fpago != null) {
        this.mensaje('Debes de subir el comprobante de pago.', 'is-warning')
        // eslint-disable-next-line
        return
      }
      if (!this.dateIn && !this.dateOut) {
        this.mensaje('Falta ingresar check-in ó check-out.', 'is-warning')
        // eslint-disable-next-line
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Atención',
        message: `¿La Reservacion capturada forma parte de un paquete?
        <b>Nota: </b>de ser asi, no se facturara hasta que el paquete este completo, en caso de que la reservación no sea un paquete pasara al proceso de facturación directamente`,
        cancelText: 'No',
        confirmText: 'Si',
        type: 'is-success',
        onConfirm: () => this.save(true),
        onCancel: () => this.save(false)
      })
    },
    async save (paquete) {
      const desglose = await HotelServices.GuardarNewReporte(this.reserva, this.proveedor, this.valor_cxs, this.agencia, this.nom_agencia,
        this.dateIn, this.dateOut, this.hotel, this.titular, this.t_pagado, this.moneda, this.netoProveedor, this.netoAgencia,
        this.m_venta, this.comisionAgencia, this.porcentaje, this.mpago, this.fpago, this.viaje, this.serie, paquete)
      if (desglose === true) {
        if (this.totalComplemento === null) {
          this.totalComplemento = this.t_pagado
        }
        const complemento = await HotelServices.SubirComplemento(this.file, this.observaciones, this.reserva, this.totalComplemento)
        if (complemento === true) {
          const invoices = await ArchivosService.subirArchivos(this.dropFiles, this.reserva)
          if (invoices === true) {
            this.mensaje('REGISTRO GUARDADO CON EXITO.', 'is-success')
            this.interruptor = !this.interruptor
          } else if (invoices.errorInfo) {
            this.mensaje('Error: ' + invoices.errorInfo[1] + '-' + invoices.errorInfo[2], 'is-warning')
          } else {
            this.mensaje('Error: Favor de comunicarse con el area de IT', 'is-warning')
          }
        } else if (complemento.errorInfo) {
          this.mensaje('Error: ' + complemento.errorInfo[1] + '-' + complemento.errorInfo[2], 'is-warning')
        } else {
          this.mensaje('Error: Favor de comunicarse con el area de IT', 'is-warning')
        }
      } else if (desglose.errorInfo) {
        this.mensaje('Error: ' + desglose.errorInfo[1] + '-' + desglose.errorInfo[2], 'is-warning')
      } else {
        this.mensaje('Error: Favor de comunicarse con el area de IT', 'is-warning')
      }
    },
    mensaje (mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo
      })
    }
  },
  computed: {
    interruptor: {
      get () {
        console.log(this.value)
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filtrarDataAgencias () {
      return this.agencias.filter(option => {
        return (option.NUM_CLIENTE.toString().toLowerCase().indexOf(this.agencia.toLowerCase())) >= 0 || (option.NOMBRE.toString().toLowerCase().indexOf(this.agencia.toLowerCase())) >= 0
      })
    }
  }
}
</script>
